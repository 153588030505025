import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  card: {
    paddingBottom: theme.spacing(0),
  },
  boxContainer: {
    marginTop: theme.spacing(-1),
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "50%",
  },
  sliderContainer: {
    position: "relative",
    "& .slick-slide": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  linkIcon: {
    color: "#000",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  roundedContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  arrow: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 2,
    color: "#000",
    borderRadius: "50%",
    padding: theme.spacing(1.5),
    "&:hover": {
      color: "#e60000",
    },
  },
  nextArrow: {
    right: "-25px", // Adjust the distance from the right side
  },
  prevArrow: {
    left: "-25px", // Adjust the distance from the left side
  },
}));

export default useStyles;
