import { PullRequest } from "./usePullRequests";
import { GithubSearchPullRequestsDataItem } from "../types";
import useStyles from "./IconsStyles";

// Define the valid statuses as a union type
type StatusType = "open" | "closed" | "merged" | "draft";

interface StatusBadgeProps {
  status: StatusType;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  const classes = useStyles();

  const statusClass =
    {
      open: classes.open,
      closed: classes.closed,
      merged: classes.merged,
      draft: classes.draft,
    }[status] || "";

  return <div className={`${classes.badge} ${statusClass}`}>{status}</div>;
};

export const getStatusIconType = (
  row: PullRequest | GithubSearchPullRequestsDataItem,
) => {
  if (row.state === "open" && row.draft) {
    return (
      <span>
        <StatusBadge status="draft" />
      </span>
    );
  }
  if (row.state === "open") {
    return (
      <span>
        <StatusBadge status="open" />
      </span>
    );
  }
  if (row.state === "closed" && row.merged) {
    return (
      <span>
        <StatusBadge status="merged" />
      </span>
    );
  }
  if (row.state === "closed") {
    return (
      <span>
        <StatusBadge status="closed" />
      </span>
    );
  }
  return null;
};
