import { Grid } from "@material-ui/core";
import { EntitySwitch } from "../../components/catalog";
import { isGithubActionsAvailable } from "@backstage-community/plugin-github-actions";
import { ReadMeCard } from "../../components/github/components";
import {
  EntityGitlabReadmeCard,
  isGitlabAvailable,
} from "../../components/gitlab";

export const readmeContent = (
  <Grid item md={12}>
    <EntitySwitch>
      <EntitySwitch.Case if={isGitlabAvailable}>
        <EntityGitlabReadmeCard />
      </EntitySwitch.Case>
      <EntitySwitch.Case if={isGithubActionsAvailable}>
        <ReadMeCard />
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
