import { Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme: Theme) => {
    return {
      button: {
        color: theme.page.fontColor,
      },
    };
  },
  { name: "PluginCatalogEntityContextMenu" },
);

export default useStyles;
