import { createPlugin } from "@backstage/core-plugin-api";

import { rootRouteRef } from "./routes";

export const InfraSelfServicePlugin = createPlugin({
  id: "infra-self-service",
  routes: {
    root: rootRouteRef,
  },
});
