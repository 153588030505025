/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { CodeSnippet } from "@backstage/core-components";
import useStyles from "./EntityLabelsEmptyStateStyles";

const ENTITY_YAML = `metadata:
  name: example
  labels:
    javaVersion: 1.2.3`;

export function EntityLabelsEmptyState() {
  const classes = useStyles();

  return (
    <>
      <Typography variant="body1">
        No labels defined for this entity. You can add labels to your entity
        YAML as shown in the highlighted example below:
      </Typography>
      <div className={classes.code}>
        <CodeSnippet
          text={ENTITY_YAML}
          language="yaml"
          showLineNumbers
          highlightedNumbers={[3, 4, 5, 6]}
          customStyle={{ background: "inherit", fontSize: "115%" }}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        target="_blank"
        href="https://backstage.io/docs/features/software-catalog/descriptor-format#labels-optional"
      >
        Read more
      </Button>
    </>
  );
}
