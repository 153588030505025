import { Entity } from "@backstage/catalog-model";
import {
  Content,
  Header,
  Link,
  Page,
  Progress,
  RoutedTabs,
  WarningPanel,
} from "@backstage/core-components";
import {
  attachComponentData,
  IconComponent,
  useElementFilter,
  useRouteRef,
  useRouteRefParams,
} from "@backstage/core-plugin-api";
import {
  entityRouteRef,
  FavoriteEntity,
  InspectEntityDialog,
  UnregisterEntityDialog,
  useAsyncEntity,
} from "@backstage/plugin-catalog-react";
import Box from "@material-ui/core/Box";
import { TabProps } from "@material-ui/core/Tab";
import Alert from "@material-ui/lab/Alert";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { EntityContextMenu } from "../EntityContextMenu/EntityContextMenu";
import { rootRouteRef, unregisterRedirectRouteRef } from "../../routes";
import GitHubIcon from "@material-ui/icons/GitHub";
import { FaGitlab } from "react-icons/fa";

/** @public */
export type EntityLayoutRouteProps = {
  path: string;
  title: any;
  children: JSX.Element;
  if?: (entity: Entity) => boolean;
  tabProps?: TabProps<React.ElementType, { component?: React.ElementType }>;
};

const dataKey = "plugin.catalog.entityLayoutRoute";
const Route: (props: EntityLayoutRouteProps) => null = () => null;
attachComponentData(Route, dataKey, true);
attachComponentData(Route, "core.gatherMountPoints", true); // This causes all mount points that are discovered within this route to use the path of the route itself

function EntityLayoutTitle(props: {
  title: string;
  entity: Entity | undefined;
}) {
  const { title, entity } = props;

  // Utility function to determine the repository hub
  const getRepoHub = (
    entity: Entity | undefined,
  ): "github" | "gitlab" | undefined => {
    if (!entity) return undefined;
    console.log(entity);
    const repoUrl = entity.metadata.annotations?.["backstage.io/view-url"]; // Assuming repo URL is in annotations
    if (repoUrl?.includes("github.com")) {
      return "github";
    } else if (repoUrl?.includes("gitlab")) {
      return "gitlab";
    }
    return undefined;
  };

  const repoHub = getRepoHub(entity);
  const isComponent = entity?.kind === "Component";

  return (
    <Box display="flex" alignItems="center">
      {/* Conditionally render the icon based on the repository hub */}
      {isComponent && repoHub === "github" && (
        <Box component="span" mr={2}>
          <GitHubIcon
            style={{
              color: "black",
              fontSize: "1.5rem",
              verticalAlign: "middle",
            }}
          />
        </Box>
      )}
      {isComponent && repoHub === "gitlab" && (
        <Box component="span" mr={2}>
          <FaGitlab
            style={{
              color: "orange",
              fontSize: "1.5rem",
              verticalAlign: "middle",
            }}
          />
        </Box>
      )}

      {/* Entity title */}
      <Box
        component="span"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
      >
        {title}
      </Box>
    </Box>
  );
}

// NOTE(freben): Intentionally not exported at this point, since it's part of
// the unstable extra context menu items concept below
interface ExtraContextMenuItem {
  title: string;
  Icon: IconComponent;
  onClick: () => void;
}

type VisibleType = "visible" | "hidden" | "disable";

// NOTE(blam): Intentionally not exported at this point, since it's part of
// unstable context menu option, eg: disable the unregister entity menu
interface EntityContextMenuOptions {
  disableUnregister: boolean | VisibleType;
}

/** @public */
export interface EntityLayoutProps {
  UNSTABLE_extraContextMenuItems?: ExtraContextMenuItem[];
  UNSTABLE_contextMenuOptions?: EntityContextMenuOptions;
  children?: React.ReactNode;
  NotFoundComponent?: React.ReactNode;
}

export const EntityLayout = (props: EntityLayoutProps) => {
  const {
    UNSTABLE_extraContextMenuItems,
    UNSTABLE_contextMenuOptions,
    children,
    NotFoundComponent,
  } = props;
  const { kind } = useRouteRefParams(entityRouteRef);
  const { entity, loading, error } = useAsyncEntity();
  const location = useLocation();
  const routes = useElementFilter(
    children,
    elements =>
      elements
        .selectByComponentData({
          key: dataKey,
          withStrictError:
            "Child of EntityLayout must be an EntityLayout.Route",
        })
        .getElements<EntityLayoutRouteProps>() // all nodes, element data, maintain structure or not?
        .flatMap(({ props: elementProps }) => {
          if (!entity) {
            return [];
          } else if (elementProps.if && !elementProps.if(entity)) {
            return [];
          }

          return [
            {
              path: elementProps.path,
              title: elementProps.title,
              children: elementProps.children,
              tabProps: elementProps.tabProps,
            },
          ];
        }),
    [entity],
  );

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [inspectionDialogOpen, setInspectionDialogOpen] = useState(false);
  const navigate = useNavigate();
  const catalogRoute = useRouteRef(rootRouteRef);
  const unregisterRedirectRoute = useRouteRef(unregisterRedirectRouteRef);

  const cleanUpAfterRemoval = async () => {
    setConfirmationDialogOpen(false);
    setInspectionDialogOpen(false);
    navigate(
      unregisterRedirectRoute ? unregisterRedirectRoute() : catalogRoute(),
    );
  };

  // Make sure to close the dialog if the user clicks links in it that navigate
  // to another entity.
  useEffect(() => {
    setConfirmationDialogOpen(false);
    setInspectionDialogOpen(false);
  }, [location.pathname]);

  return (
    <Page themeId={entity?.spec?.type?.toString() ?? "home"}>
      <Header
        title={
          <EntityLayoutTitle title={entity!.metadata?.name} entity={entity!} />
        }
        pageTitleOverride={entity!.metadata?.name}
      >
        {entity && (
          <>
            {entity && <FavoriteEntity entity={entity} />}
            <EntityContextMenu
              UNSTABLE_extraContextMenuItems={UNSTABLE_extraContextMenuItems}
              UNSTABLE_contextMenuOptions={UNSTABLE_contextMenuOptions}
              onUnregisterEntity={() => setConfirmationDialogOpen(true)}
              onInspectEntity={() => setInspectionDialogOpen(true)}
            />
          </>
        )}
      </Header>

      {loading && <Progress />}

      {entity && <RoutedTabs routes={routes} />}

      {error && (
        <Content>
          <Alert severity="error">{error.toString()}</Alert>
        </Content>
      )}

      {!loading && !error && !entity && (
        <Content>
          {NotFoundComponent ? (
            NotFoundComponent
          ) : (
            <WarningPanel title="Entity not found">
              There is no {kind} with the requested{" "}
              <Link to="https://backstage.io/docs/features/software-catalog/references">
                kind, namespace, and name
              </Link>
              .
            </WarningPanel>
          )}
        </Content>
      )}

      <UnregisterEntityDialog
        open={confirmationDialogOpen}
        entity={entity!}
        onConfirm={cleanUpAfterRemoval}
        onClose={() => setConfirmationDialogOpen(false)}
      />
      <InspectEntityDialog
        open={inspectionDialogOpen}
        entity={entity!}
        onClose={() => setInspectionDialogOpen(false)}
      />
    </Page>
  );
};

EntityLayout.Route = Route;
