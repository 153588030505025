enum IacRepoComponent {
  AWSACCOUNT = "aws-account",
  AWSREGION = "aws-region",
  IACENV = "iac-environment",
  IACSUBENV = "iac-subenvironment",
}

const IacRepoRoot = "root";

const IacRepoComponents = Object.values(IacRepoComponent);

export const GenerateIacTargetPath = async (
  filePath: string,
  matchPathUpto: string,
  catalogApi: any,
) => {
  if (matchPathUpto === IacRepoRoot) {
    return ".";
  }

  const availableResourceParts = IacRepoComponents.slice(
    0,
    IacRepoComponents.indexOf(matchPathUpto as IacRepoComponent) + 1,
  );

  console.log("availableResourceParts: ", availableResourceParts);

  const fileParts = filePath.split("/");
  const targetPathParts = [];
  for (let i = 0; i < availableResourceParts.length; i++) {
    const res = await catalogApi.getEntities({
      filter: {
        "spec.type": availableResourceParts[i],
        "metadata.name": fileParts[i],
      },
    });
    if (res.items.length > 0) {
      targetPathParts.push(fileParts[i]);
    } else {
      break;
    }
  }
  return targetPathParts.join("/");
};
