import { useState } from "react";
import { Button, makeStyles, Box, Typography } from "@material-ui/core";
import {
  gitlabAuthApiRef,
  githubAuthApiRef,
  useApi,
  BackstageIdentityResponse,
  SignInPageProps,
} from "@backstage/core-plugin-api";
import GitHubIcon from "@material-ui/icons/GitHub";
import { FaGitlab } from "react-icons/fa";
import { useMountEffect } from "@react-hookz/web";
import {
  IdentityProviders,
  Progress,
  UserIdentity,
} from "@backstage/core-components";

type MultiCustomSignInPageProps = SignInPageProps & {
  providers: IdentityProviders;
  auto?: boolean;
};

export type Props = MultiCustomSignInPageProps;

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#B22222",
    opacity: 0.8,
    backgroundImage:
      "repeating-radial-gradient( circle at center, transparent 0, #FF0000 10px ), repeating-linear-gradient( #B22222, #8B0000 )",
  },
  button: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  githubButton: {
    backgroundColor: "#24292e",
    color: "#fff",
    display: "flex", // This will allow the logo to be centered
    marginLeft: "auto", // These two lines will center the logo
    marginRight: "auto",
    "&:hover": {
      backgroundColor: "#404448",
    },
    width: "80%",
  },
  gitlabButton: {
    backgroundColor: "#fc6d26",
    color: "#fff",
    display: "flex", // This will allow the logo to be centered
    marginLeft: "auto", // These two lines will center the logo
    marginRight: "auto",
    "&:hover": {
      backgroundColor: "#e65c00",
    },
    width: "80%",
  },
  box: {
    backgroundColor: "#fff",
    padding: "10px 10px 40px 10px",
    borderRadius: "5px",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: "50px",
    color: "#282c34",
    textAlign: "center",
  },
  logo: {
    width: "280px", // Adjust the width as needed
    height: "auto", // This will maintain the aspect ratio
    marginBottom: "20px", // Add some space below the logo
    display: "block", // This will allow the logo to be centered
    marginLeft: "auto", // These two lines will center the logo
    marginRight: "auto",
  },
});

export const MultiCustomSignInPage = ({
  auto,
  onSignInSuccess,
}: MultiCustomSignInPageProps) => {
  // Fix: Explicitly define the type of the 'props' parameter
  const classes = useStyles();
  const gitlabAuthApi = useApi(gitlabAuthApiRef);
  const githubAuthApi = useApi(githubAuthApiRef);

  const [, setError] = useState<Error>();

  // The SignIn component takes some time to decide whether the user is logged-in or not.
  // showLoginPage is used to prevent a glitch-like experience where the sign-in page is
  // displayed for a split second when the user is already logged-in.
  const [showLoginPage, setShowLoginPage] = useState<boolean>(false);
  type LoginOpts = { checkExisting?: boolean; showPopup?: boolean };

  const handleGithubSignIn = async ({
    checkExisting,
    showPopup,
  }: LoginOpts) => {
    try {
      let identityResponse: BackstageIdentityResponse | undefined;
      if (checkExisting) {
        // Do an initial check if any logged-in session exists
        identityResponse = await githubAuthApi.getBackstageIdentity({
          optional: true,
        });
      }

      // If no session exists, show the sign-in page
      if (!identityResponse && (showPopup || auto)) {
        // Unless auto is set to true, this step should not happen.
        // When user intentionally clicks the Sign In button, autoShowPopup is set to true
        setShowLoginPage(true);
        identityResponse = await githubAuthApi.getBackstageIdentity({
          instantPopup: true,
        });
        if (!identityResponse) {
          throw new Error(
            "Github provider is not configured to support sign-in",
          );
        }
      }

      if (!identityResponse) {
        setShowLoginPage(true);
        return;
      }

      const profile = await githubAuthApi.getProfile();
      onSignInSuccess(
        UserIdentity.create({
          identity: identityResponse.identity,
          authApi: githubAuthApi,
          profile,
        }),
      );
    } catch (err: any) {
      // User closed the sign-in modal
      setError(err);
      setShowLoginPage(true);
    }
  };

  const handleGitlabSignIn = async ({
    checkExisting,
    showPopup,
  }: LoginOpts) => {
    try {
      let identityResponse: BackstageIdentityResponse | undefined;

      if (checkExisting) {
        // Do an initial check if any logged-in session exists
        identityResponse = await gitlabAuthApi.getBackstageIdentity({
          optional: true,
        });
      }

      // If no session exists, show the sign-in page
      if (!identityResponse && (showPopup || auto)) {
        // Unless auto is set to true, this step should not happen.
        // When user intentionally clicks the Sign In button, autoShowPopup is set to true
        setShowLoginPage(true);

        identityResponse = await gitlabAuthApi.getBackstageIdentity({
          instantPopup: true,
        });

        if (!identityResponse) {
          throw new Error(
            "Gitlab provider is not configured to support sign-in",
          );
        }
      }

      if (!identityResponse) {
        setShowLoginPage(true);
        return;
      }

      const profile = await gitlabAuthApi.getProfile();
      onSignInSuccess(
        UserIdentity.create({
          identity: identityResponse.identity,
          authApi: gitlabAuthApi,
          profile,
        }),
      );
    } catch (err: any) {
      // User closed the sign-in modal
      setError(err);
      setShowLoginPage(true);
    }
  };

  useMountEffect(() => handleGithubSignIn({ checkExisting: true }));
  useMountEffect(() => handleGitlabSignIn({ checkExisting: true }));

  return showLoginPage ? (
    <div className={classes.root}>
      <Box className={classes.box}>
        <img
          src="/png/vf-logo.png"
          alt="Vodafone Logo"
          className={classes.logo}
        />
        <Typography className={classes.title}>TowerBridge</Typography>

        <Button
          variant="contained"
          className={`${classes.button} ${classes.githubButton}`}
          startIcon={<GitHubIcon />}
          onClick={() => {
            handleGithubSignIn({ showPopup: true });
          }}
        >
          Sign in with GitHub
        </Button>
        <Button
          variant="contained"
          className={`${classes.button} ${classes.gitlabButton}`}
          startIcon={<FaGitlab />}
          onClick={() => {
            handleGitlabSignIn({ showPopup: true });
          }}
        >
          Sign in with GitLab
        </Button>
      </Box>
    </div>
  ) : (
    <Progress />
  );
};

export function CustomSignInPage(props: Props) {
  return <MultiCustomSignInPage {...props} />;
}
