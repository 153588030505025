import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  badge: {
    borderRadius: "8px",
    fontWeight: "bold",
    display: "inline-block",
    color: "#fff",
    lineHeight: "1.5",
    whiteSpace: "nowrap",
  },
  inProgress: {
    backgroundColor: "#deebff",
    color: "#0747a6",
  },
  blocked: {
    backgroundColor: "#ffe8e6",
    color: "#bf2600",
  },
  draft: {
    backgroundColor: "#f4f5f7",
    color: "#505f79",
  },
  small: {
    padding: "2px 4px",
    fontSize: "0.75rem",
    height: "24px",
  },
  medium: {
    padding: "2px 8px",
    fontSize: "0.875rem",
    height: "28px",
  },
  large: {
    padding: "4px 12px",
    fontSize: "1rem",
    height: "32px",
  },
}));

export default useStyles;
