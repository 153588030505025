import { appThemeApiRef, useApi } from "@backstage/core-plugin-api";
import { IconButton } from "@mui/material";

import { useObservable } from "react-use";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import { LightMode } from "@mui/icons-material";
import { Box } from "@material-ui/core";
import useStyles from "./ThemeButtonStyles";

export const SettingsThemeToggle = () => {
  const classes = useStyles();
  const appThemeApi = useApi(appThemeApiRef);
  const activeThemeId = useObservable(
    appThemeApi.activeThemeId$(),
    appThemeApi.getActiveThemeId(),
  );

  const handleSetTheme = () => {
    if (activeThemeId == "vf-theme-dark") {
      appThemeApi.setActiveThemeId("vf-theme-light");
    } else {
      appThemeApi.setActiveThemeId("vf-theme-dark");
    }
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ py: 1, px: 2 }}
      className={classes.button}
    >
      <IconButton onClick={handleSetTheme} className={classes.icon}>
        {activeThemeId === "vf-theme-dark" ? (
          <Brightness3Icon />
        ) : (
          <LightMode />
        )}
      </IconButton>
    </Box>
  );
};
