import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  lineChartContainer: {
    "& .v5-MuiChartsAxis-directionX .v5-MuiChartsAxis-tickLabel": {
      transform: "rotate(90deg)",
      textAnchor: "start",
    },
    "& .v5-MuiLineElement-root, .MuiMarkElement-root": {
      strokeWidth: 1,
    },
  },
}));

export default useStyles;
