import { useProjectEntity } from "../../hooks/useProjectEntity";
import { useEntity } from "@backstage/plugin-catalog-react";
import { useEntityGithubScmIntegration } from "../../hooks/useEntityGithubScmIntegration";
import { Card } from "@material-ui/core";
import { MarkdownContent } from "..";

const ReadMeCard = () => {
  const { entity } = useEntity();
  const { owner, repo, readmePath } = useProjectEntity(entity);
  const { baseUrl } = useEntityGithubScmIntegration(entity);

  return (
    <Card>
      <MarkdownContent
        baseUrl={baseUrl}
        owner={owner}
        repo={repo}
        path={readmePath}
      />
    </Card>
  );
};

export default ReadMeCard;
