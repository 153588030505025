import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import LogoFullWhite from "../branding/LogoFullWhite";
import { Link, SidebarGroup, SidebarItem } from "@backstage/core-components";
import { Box, styled } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { ReactNode } from "react";
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from "@backstage/plugin-user-settings";
import CreateComponentIcon from "@material-ui/icons/AddCircleOutline";
import ToolsMenu from "./ToolsMenu";
import { SidebarSearchModal } from "@backstage/plugin-search";
import { IconComponent } from "@backstage/core-plugin-api";
import { SettingsThemeToggle } from "./ThemeButton";
import useStyles from "./TopBarStyles";

const TopBarLogo = () => {
  const classes = useStyles();

  return (
    <div className={classes.logo}>
      <Link to="/" underline="none" aria-label="Home">
        <LogoFullWhite />
      </Link>
    </div>
  );
};

type ItemProps = {
  icon?: IconComponent;
  text?: string;
  to: string;
  hasSubmenu?: boolean;
  onClick?: (ev: React.MouseEvent) => void;
  children?: ReactNode;
};

export const NavItem = (Item: ItemProps) => {
  const classes = useStyles();

  return (
    <Link to={Item.to} underline="none" className={classes.appbar}>
      <Box
        display="flex"
        alignItems="center"
        sx={{ py: 2, px: 4 }}
        className={classes.button}
      >
        {Item.text}
      </Box>
    </Link>
  );
};

export const TopBarSpace = styled("div")({
  flex: 1,
});

function ResponsiveAppBar() {
  const classes = useStyles();

  return (
    <>
      <AppBar position="fixed">
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <TopBarLogo />
            <SidebarGroup label="Menu" icon={<MenuIcon />}>
              <NavItem to="/" text="HOME" />
              <NavItem to="catalog" text="CATALOG" />
              <NavItem to="self-service" text="SELF SERVICE" />
              <NavItem to="docs" text="DOCS" />
              <ToolsMenu />
            </SidebarGroup>
            <TopBarSpace />
            <SettingsThemeToggle />
            <SidebarGroup
              label="Search"
              icon={<SearchIcon className={classes.search} />}
              to="/search"
            >
              <SidebarSearchModal />
            </SidebarGroup>
            <SidebarGroup
              label="Settings"
              icon={<UserSettingsSignInAvatar />}
              to="/settings"
              style={{ alignSelf: "flex-end" }}
            >
              <SidebarItem
                icon={CreateComponentIcon}
                to="create"
                text="Create..."
              />
              <SidebarSettings />
            </SidebarGroup>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </>
  );
}
export default ResponsiveAppBar;
