import { EntityLayout } from "../../components/catalog";
import { Grid } from "@material-ui/core";
import { EntityApiDefinitionCard } from "@backstage/plugin-api-docs";
import { overviewContent } from "../content/OverviewContent";

const titleWithAvatar = (src: string, text: string) => (
  <span style={{ display: "flex", alignItems: "center" }}>
    <img src={src} style={{ marginRight: 8, width: 18, height: 18 }} />
    {text}
  </span>
);

export const apiEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route
      path="/definition"
      title={titleWithAvatar("/svg/swagger.svg", "Swagger UI")}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
