import {
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityLayout,
} from "../../components/catalog";
import { cicdContent } from "../content/CICDContent";
import { PullRequestContent } from "../content/PullRequestContent";
import { overviewContent } from "../content/OverviewContent";
import { Grid } from "@material-ui/core";
import { scoreCardContent } from "../content/ScoreCardContent";
import { readmeContent } from "../content/ReadmeContent";
import {
  EntityConsumedApisCard,
  EntityProvidedApisCard,
} from "@backstage/plugin-api-docs";
import { techdocsContent } from "../content/TechDocsContent";
import { EntityKubernetesContent } from "@backstage/plugin-kubernetes";

const titleWithAvatar = (src: string, text: string) => (
  <span style={{ display: "flex", alignItems: "center" }}>
    <img src={src} style={{ marginRight: 8, width: 18, height: 18 }} />
    {text}
  </span>
);

export const componentEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/readme"
      title={titleWithAvatar("/png/book.png", "Readme")}
    >
      {readmeContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/scorecard"
      title={titleWithAvatar("/png/score.png", "ScoreCards")}
    >
      {scoreCardContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/api"
      title={titleWithAvatar("/svg/swagger.svg", "API")}
    >
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/ci-cd"
      title={titleWithAvatar("/png/cicd.png", "CI/CD")}
    >
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/dependencies"
      title={titleWithAvatar("/png/relations.png", "Dependencies")}
    >
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/pull-requests"
      title={titleWithAvatar("/png/git.png", "Pull Requests")}
    >
      {PullRequestContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/docs"
      title={titleWithAvatar("/png/google-docs.png", "Docs")}
    >
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/kubernetes"
      title={titleWithAvatar("/png/kubernetes.png", "Kubernetes")}
    >
      <EntityKubernetesContent refreshIntervalMs={30000} />
    </EntityLayout.Route>
  </EntityLayout>
);
