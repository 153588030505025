import { useState } from "react";
import Modal from "react-modal";
import { jiraApiRef } from "../../jira/api";
import { identityApiRef, useApi } from "@backstage/core-plugin-api";
import { FiExternalLink, FiMessageSquare } from "react-icons/fi";
import { catalogApiRef } from "@backstage/plugin-catalog-react";
import useStyles from "./FloatingButtonStyle";
import { ticketTemplates } from "../../jira/";
import { PopupAlert, AlertType } from "../PopupAlert";

Modal.setAppElement("#root");
const FORMS_URL = "https://forms.office.com/e/YwJPRc5fJE";

interface AlertState {
  title: string;
  ticketId: string;
  type: AlertType;
  visible: boolean;
}

export const FloatingButton = () => {
  const classes = useStyles();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isJiraFormVisible, setIsJiraFormVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<AlertState>({
    title: "",
    ticketId: "",
    type: AlertType.SUCCESS,
    visible: false,
  });

  const jiraApi = useApi(jiraApiRef);
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);

  const handleOpenModal = () => setModalIsOpen(true);
  const handleCloseModal = () => {
    setModalIsOpen(false);
    setIsJiraFormVisible(false);
    setTitle("");
    setDescription("");
  };
  const handleJiraButtonClick = () => setIsJiraFormVisible(true);
  const handleAlertClose = () => setAlert({ ...alert, visible: false });

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { userEntityRef } = await identityApi.getBackstageIdentity();
      const userEntityName = userEntityRef.split("/")[1];
      const userEntity = await catalogApi.getEntityByRef(
        `user:default/${userEntityName}`,
      );
      const userEmail = userEntity?.metadata?.email as string;

      const reporter = await jiraApi.getReporter(userEmail);
      const towerbridgeTemplate = ticketTemplates["TowerbridgeFeedback"];

      const response = await jiraApi.createJiraTicket({
        summary: title,
        description,
        reporter,
        ...towerbridgeTemplate,
      });

      setAlert({
        title: "Jira ticket created successfully!",
        ticketId: response.key, // Just the key, not an HTML link
        type: AlertType.SUCCESS,
        visible: true,
      });
      handleCloseModal();
    } catch (error) {
      console.error("Failed to create Jira ticket:", error);
      setAlert({
        title: "Error creating Jira ticket.",
        ticketId: "",
        type: AlertType.ERROR,
        visible: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button className={classes.floatButton} onClick={handleOpenModal}>
        <FiMessageSquare size={32} />
      </button>

      {/* Render the Alert component if alert is visible */}
      {alert.visible && (
        <PopupAlert
          isOpen={alert.visible}
          title={alert.title}
          ticketId={alert.ticketId}
          type={alert.type}
          onClose={handleAlertClose}
        />
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Actions"
        shouldCloseOnOverlayClick={true}
        className={classes.modal}
        style={{ overlay: { background: "transparent" } }}
      >
        <div className={classes.modalContent}>
          {!isJiraFormVisible ? (
            <>
              <button className={classes.actionButton}>
                <a
                  href={FORMS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Give Feedback <FiExternalLink style={{ marginLeft: "5px" }} />
                </a>
              </button>
              <button
                className={classes.actionButton}
                onClick={handleJiraButtonClick}
              >
                Report an Issue
              </button>
            </>
          ) : (
            <form className={classes.form} onSubmit={handleFormSubmit}>
              <span>
                <b>Title:</b>
              </span>
              <input
                type="text"
                value={title}
                className={classes.input}
                onChange={e => setTitle(e.target.value)}
                required
              />
              <span>
                <b>Description:</b>
              </span>
              <textarea
                value={description}
                className={classes.textArea}
                onChange={e => setDescription(e.target.value)}
                required
              />
              <button
                className={classes.actionButton}
                type="submit"
                disabled={loading}
              >
                {loading ? "Creating..." : <> Create Jira Ticket</>}
              </button>
            </form>
          )}
        </div>
      </Modal>
    </>
  );
};
