import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  open: {
    fill: "#22863a",
  },
  closed: {
    fill: "#cb2431",
  },
  merged: {
    fill: "#6f42c1",
  },
  draft: {
    fill: "#6a737d",
  },
}));

export default useStyles;
