// StatusBadgeStyles.ts
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  badge: {
    borderRadius: "50%",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  small: {
    padding: "2px",
    fontSize: "0.75rem",
    height: "24px",
    width: "24px",
  },
  medium: {
    padding: "4px",
    fontSize: "0.875rem",
    height: "28px",
    width: "28px",
  },
  large: {
    padding: "8px",
    fontSize: "1rem",
    height: "32px",
    width: "32px",
  },
  lowScore: {
    backgroundColor: "#d62839", // Darker red for low scores
    color: "#f8f9fa", // Very light gray for soft contrast
  },
  mediumScore: {
    backgroundColor: "#f1c40f", // Bright yellow for medium scores
    color: "#2c3e50", // Darker gray for contrast
  },
  highScore: {
    backgroundColor: "#2ecc71", // Bright green for high scores
    color: "#ffffff", // White text for high contrast
  },
});

export default useStyles;
