import { Theme, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoCard: {
      marginBottom: theme.spacing(3),
      "& + .MuiAlert-root": {
        marginTop: theme.spacing(3),
      },
    },
    root: {
      flexGrow: 1,
      fontSize: "0.75rem",
      "& > * + *": {
        marginTop: theme.spacing(1),
      },
    },
    ticketLink: {
      color: theme.palette.link,
      textDecoration: "none",
      transition: "color 0.3s",
      "&:hover": {
        color: theme.palette.linkHover,
      },
    },
  }),
);

export default useStyles;
