import {
  SettingsLayout,
  UserSettingsAppearanceCard,
  UserSettingsFeatureFlags,
  //UserSettingsGeneral,
} from "@backstage/plugin-user-settings";
import { UserSettingsGeneral } from "./UserSettingsGeneral";
import { Paper } from "@material-ui/core";

export const settingsPage = (
  <SettingsLayout>
    <SettingsLayout.Route path="profile" title="Profile">
      <UserSettingsGeneral />
    </SettingsLayout.Route>
    <SettingsLayout.Route path="appearance" title="Appearance">
      <Paper square={false} elevation={2}>
        <UserSettingsAppearanceCard />
      </Paper>
    </SettingsLayout.Route>
    <SettingsLayout.Route path="feature-flags" title="Feature Flags">
      <UserSettingsFeatureFlags />
    </SettingsLayout.Route>
  </SettingsLayout>
);
