import { Alert } from "@material-ui/lab";
import { Progress } from "@backstage/core-components";
import {
  ApiHolder,
  githubAuthApiRef,
  useApiHolder,
} from "@backstage/core-plugin-api";
import { MarkdownContentProps } from "./types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"; // GitHub flavored markdown
import rehypeRaw from "rehype-raw";
import rehypeHighlight from "rehype-highlight";
import "highlight.js/styles/github.css";
import rehypeFormat from "rehype-format";
import remarkEmoji from "remark-emoji";
import useAsync from "react-use/lib/useAsync";
import { GithubApi, GithubClient, githubApiRef } from "../../api";
import useStyles from "./MarkDownContentStyle";

const getGithubClient = (apiHolder: ApiHolder) => {
  let githubClient: GithubApi | undefined = apiHolder.get(githubApiRef);
  if (!githubClient) {
    const auth = apiHolder.get(githubAuthApiRef);
    if (auth) {
      githubClient = new GithubClient({ githubAuthApi: auth });
    }
  }
  if (!githubClient) {
    throw new Error(
      "The MarkdownCard component Failed to get the github client",
    );
  }
  return githubClient;
};

const GithubFileContent = (props: MarkdownContentProps) => {
  const { preserveHtmlComments } = props;
  const apiHolder = useApiHolder();
  const classes = useStyles();

  const { value, loading, error } = useAsync(async () => {
    const githubClient = getGithubClient(apiHolder);
    return githubClient.getContent({ ...props });
  }, [apiHolder]);

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (!value) {
    return <Progress />;
  }

  let content = value.content;
  if (!preserveHtmlComments) {
    content = content.replace(/<!--(.|\n)*?-->/g, "");
  }

  return (
    <div className={classes.markdown}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkEmoji]} // Added emoji support
        rehypePlugins={[rehypeRaw, rehypeHighlight, rehypeFormat]} // Format HTML for better structure
      >
        {content ?? "No README found"}
      </ReactMarkdown>
    </div>
  );
};

const MarkDownContent = (props: MarkdownContentProps) => {
  return <GithubFileContent {...props} />;
};

export default MarkDownContent;
