import ReactMarkdown from "react-markdown";
import Alert from "@material-ui/lab/Alert";
import { Progress } from "@backstage/core-components";
import { GitlabCIApiRef } from "../../../api";
import { useApi } from "@backstage/core-plugin-api";
import { useAsync } from "react-use";
import {
  gitlabProjectId,
  gitlabProjectSlug,
  gitlabReadmePath,
  gitlabInstance,
} from "../../gitlabAppData";

import useStyles from "./ReadmeCardStyle";
import { Card } from "@material-ui/core";
import remarkGfm from "remark-gfm";
import remarkEmoji from "remark-emoji";
import rehypeRaw from "rehype-raw";
import rehypeHighlight from "rehype-highlight";
import rehypeFormat from "rehype-format";

export const ReadmeCard = () => {
  const classes = useStyles();
  const project_id = gitlabProjectId();
  const project_slug = gitlabProjectSlug();
  const gitlab_instance = gitlabInstance();
  const readme_path = gitlabReadmePath();

  const GitlabCIAPI = useApi(GitlabCIApiRef).build(
    gitlab_instance || "gitlab.com",
  );

  const { value, loading, error } = useAsync(async (): Promise<{
    readme: string | undefined;
  }> => {
    const projectDetails = await GitlabCIAPI.getProjectDetails(
      project_slug || project_id,
    );

    if (!projectDetails) throw new Error("wrong project_slug or project_id");

    let readmeData: string | undefined = undefined;
    try {
      readmeData = await GitlabCIAPI.getReadme(
        projectDetails.id,
        projectDetails.default_branch,
        readme_path,
      );
    } catch {
      readmeData = undefined;
    }

    return {
      readme: readmeData,
    };
  }, []);

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Card title="README">
      <div className={classes.markdown}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm, remarkEmoji]} // Added emoji support
          rehypePlugins={[rehypeRaw, rehypeHighlight, rehypeFormat]} // Format HTML for better structure
        >
          {value?.readme ?? "No README found"}
        </ReactMarkdown>
      </div>
    </Card>
  );
};
