import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => {
  const isDarkMode = theme.palette.type === "dark";

  return {
    avatar: {
      width: 24,
      height: 24,
      marginRight: theme.spacing(1),
    },
    cellText: {
      color: isDarkMode ? "#FFFFFF" : "#000000",
    },
    rankIcon: {
      color: isDarkMode ? "#ff4d4d" : "#e60000",
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    titleBox: {
      display: "flex",
      alignItems: "left",
      justifyContent: "left",
    },
    progressBar: {
      width: "75%",
      height: theme.spacing(1),
      borderRadius: 10,
    },
    productionReadinessColor: {
      backgroundColor: "#FFEB3B",
    },
    monitoringMaturityColor: {
      backgroundColor: "#03A9F4",
    },
    doraMetricsColor: {
      backgroundColor: "#FF9800",
    },
  };
});

export default useStyles;
