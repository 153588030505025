import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  appbar: {
    color: "#FFFFFF",
  },
  logo: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    marginRight: 20,
  },
  button: {
    "&:hover": {
      background:
        theme.palette.navigation.navItem?.hoverBackground || "#404040",
    },
  },
  search: {
    borderRadius: 0,
    "&:hover": {
      borderRadius: 0,
    },
  },
}));

export default useStyles;
