export const ticketTemplates = {
  TowerbridgeFeedback: {
    projectKey: "PPEP",
    issueType: "Story",
    components: [
      {
        self: "https://jira.tools.aws.vodafone.com/rest/api/2/component/54402",
        id: "54402",
        name: "CPE - DevEx",
        description:
          "Component for 'Developer Experience' Squad features, user stories, spikes and technical doc and implementation tasks",
      },
    ],
    labels: ["IDP", "Improvements", "Maintenance", "PPE_Platform"],
    // Feature Link
    customfield_10006: "PPEP-517",
    // Agile Release Train
    customfield_11701: [
      {
        self: "https://jira.tools.aws.vodafone.com/rest/api/2/customFieldOption/186408",
        value: "PPE - Platform - Cloud Platform ART",
        id: "186408",
        disabled: "false",
      },
    ],
    // Agile Team
    customfield_11018: {
      self: "https://jira.tools.aws.vodafone.com/rest/api/2/customFieldOption/231003",
      value: "DevEX",
      id: "231003",
      disabled: "false",
    },
  },
};
