import {
  CostInsightsApi,
  Alert,
  Cost,
  Group,
  MetricData,
  Project,
  Entity,
} from "@backstage-community/plugin-cost-insights";

export class CostInsightsClient implements CostInsightsApi {
  async getLastCompleteBillingDate(): Promise<string> {
    return "2021-01-01"; // YYYY-MM-DD
  }

  async getUserGroups(): Promise<Group[]> {
    return [];
  }

  async getGroupProjects(): Promise<Project[]> {
    return [];
  }

  async getAlerts(): Promise<Alert[]> {
    return [];
  }

  async getDailyMetricData(): Promise<MetricData> {
    return {
      id: "remove-me",
      format: "number",
      aggregation: [],
      change: {
        ratio: 0,
        amount: 0,
      },
    };
  }

  async getGroupDailyCost(): Promise<Cost> {
    return {
      id: "remove-me",
      aggregation: [],
      change: {
        ratio: 0,
        amount: 0,
      },
    };
  }

  async getProjectDailyCost(): Promise<Cost> {
    return {
      id: "remove-me",
      aggregation: [],
      change: {
        ratio: 0,
        amount: 0,
      },
    };
  }

  async getCatalogEntityDailyCost(): Promise<Cost> {
    return {
      id: "remove-me",
      aggregation: [],
      change: {
        ratio: 0,
        amount: 0,
      },
    };
  }

  async getProductInsights(): Promise<Entity> {
    return {
      id: "remove-me",
      aggregation: [0, 0],
      change: {
        ratio: 0,
        amount: 0,
      },
      entities: {},
    };
  }
}
