import Modal from "react-modal";
import { FiX } from "react-icons/fi";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { AlertType } from "./types";
import useStyles from "./PopupAlertStyle";
interface PopupModalProps {
  isOpen: boolean;
  title: string;
  ticketId: string;
  type: AlertType;
  onClose: () => void;
}

export const PopupAlert = ({
  isOpen,
  title,
  ticketId,
  type,
  onClose,
}: PopupModalProps) => {
  const classes = useStyles();

  // Construct the URL for the ticket
  const ticketUrl = `https://jira.tools.aws.vodafone.com/browse/${ticketId}`;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={classes.popup}
      overlayClassName={classes.overlay}
      contentLabel="Popup Alert"
    >
      <div className={classes.header}>
        <button onClick={onClose} className={classes.closeButton}>
          <FiX size={20} />
        </button>
      </div>
      <div className={classes.content}>
        {type === "success" ? (
          <AiOutlineCheckCircle size={36} className={classes.iconSuccess} />
        ) : (
          <AiOutlineCloseCircle size={36} className={classes.iconError} />
        )}
        <span className={classes.title}>{title}</span>
        {ticketId && (
          <span className={classes.title}>
            Ticket ID:{" "}
            <a
              href={ticketUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.ticketLink}
            >
              {ticketId}
            </a>
          </span>
        )}
      </div>
    </Modal>
  );
};
