import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Tooltip,
  Box,
  Link,
} from "@material-ui/core";
import { useApi, identityApiRef } from "@backstage/core-plugin-api";
import { catalogApiRef } from "@backstage/plugin-catalog-react";
import { jiraApiRef } from "../../api";
import { Ticket } from "../../types";
import useStyles from "./AssignedTicketsCardStyles";
import { StatusBadge } from "../StatusBadge";
import { CustomDataGrid } from "../../../shared/CustomDataGrid/CustomDataGrid";

export const AssignedTicketsCard = () => {
  const classes = useStyles();

  const [issues, setIssues] = useState<Ticket[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);
  const jiraApi = useApi(jiraApiRef);

  const fetchIssues = async () => {
    setLoading(true);
    setError(undefined);
    try {
      const { userEntityRef } = await identityApi.getBackstageIdentity();
      const userEntityName = userEntityRef.split("/")[1];
      const userEntity = await catalogApi.getEntityByRef(
        `user:default/${userEntityName}`,
      );
      const userEmail = userEntity?.metadata?.email;

      if (!userEmail) {
        throw new Error("User email not found");
      }

      const response = await jiraApi.getIssuesAssignedToUser(
        userEmail as string,
      );
      setIssues(response.issues);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIssues();
  }, [identityApi, jiraApi]);

  // Define columns for DataGrid
  const columns = [
    {
      field: "key",
      headerName: "KEY",
      flex: 0.5,
      renderCell: (params: any) => (
        <Link
          href={`https://jira.tools.aws.vodafone.com/browse/${params.value}`}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.keyLink}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "summary",
      headerName: "SUMMARY",
      flex: 1,
      renderCell: (params: any) => <Typography>{params.value}</Typography>,
    },
    {
      field: "priority",
      headerName: "PRIORITY",
      flex: 0.5,
      renderCell: (params: any) => (
        <Tooltip title={params.value || ""} arrow>
          <img
            src={params.row.priorityIcon}
            alt={params.value}
            className={classes.priorityIcon}
          />
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 0.5,
      renderCell: (params: any) => (
        <StatusBadge status={params.value} size="small" />
      ),
    },
    {
      field: "assignee",
      headerName: "ASSIGNEE",
      flex: 0.5,
      renderCell: (params: any) => (
        <Box>
          {params.value ? (
            <Tooltip title={params.value.displayName} arrow>
              <Avatar
                alt={params.value.displayName}
                src={params.value.avatarUrls["48x48"]}
                className={classes.avatar}
              />
            </Tooltip>
          ) : (
            <span>Not Assigned</span>
          )}
        </Box>
      ),
    },
  ];

  // Transform data for DataGrid
  const rows = issues.map(issue => ({
    id: issue.id,
    key: issue.key,
    summary: issue.fields?.summary,
    priority: issue.fields?.priority?.name || "", // Use the name for sorting
    priorityIcon: issue.fields?.priority?.iconUrl,
    status: issue.fields?.status?.name,
    assignee: issue.fields?.assignee,
  }));

  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar src="/svg/jira.svg" className={classes.jiraIcon} />
          <Typography variant="h5" gutterBottom>
            Assigned Jira Tickets
          </Typography>
        </Box>
        <CustomDataGrid
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          loading={loading}
          error={error}
        />
      </CardContent>
    </Card>
  );
};

export default AssignedTicketsCard;
