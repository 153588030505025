import { Header, HeaderLabel, Page } from "@backstage/core-components";
import { createRoutableExtension } from "@backstage/core-plugin-api";
import { useEntity } from "@backstage/plugin-catalog-react";
import {
  InfraSelfServicePlugin,
  InfraSelfService,
} from "@internal/backstage-plugin-self-service";
import { rootRouteRef } from "@internal/backstage-plugin-self-service/src/routes";

export const SelfServicePage = InfraSelfServicePlugin.provide(
  createRoutableExtension({
    name: "InfraSelfServicePageV2",
    component: () =>
      Promise.resolve(() => {
        return (
          <Page themeId="tool">
            <Header title="Welcome to Self-service">
              <HeaderLabel label="Owner" value="CloudInfra/DevEx" />
              <HeaderLabel label="Lifecycle" value="Development" />
            </Header>
            <InfraSelfService />
          </Page>
        );
      }),
    mountPoint: rootRouteRef,
  }),
);

export const SelfService = () => {
  const entityRef = useEntity();
  return <InfraSelfService entity={entityRef.entity} />;
};
