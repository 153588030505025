import { Box } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from "@mui/x-data-grid";
import useStyles from "./CustomPaginationStyles";

export const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const classes = useStyles();
  return (
    <Box className={classes.paginationContainer}>
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        renderItem={item => (
          <PaginationItem
            {...item}
            component="div"
            className={classes.paginationItem}
          />
        )}
        onChange={(_event: React.ChangeEvent<unknown>, value: number) =>
          apiRef.current.setPage(value - 1)
        }
      />
    </Box>
  );
};

export default CustomPagination;
