import { Grid, Typography } from "@material-ui/core";
import { SearchContextProvider } from "@backstage/plugin-search-react";
import { Content, Page } from "@backstage/core-components";
import {
  HomePageRequestedReviewsCard,
  HomePageYourOpenPullRequestsCard,
} from "../components/github";
import { UsefulLinks } from "../components/home/UsefulLinks/UsefulLinks";
import { PersonalJiraOverviewCard } from "../components/jira";
import { TeamInsightsCard } from "../components/home/TeamInsightsCard";

export const HomePage = () => {
  /* We will shortly compose a pretty homepage here. */
  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={2}>
            <Grid container item xs={12} justifyContent="center">
              <Typography variant="h3" gutterBottom>
                Welcome to TowerBridge
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <PersonalJiraOverviewCard />
              </Grid>
              <Grid item xs={6}>
                <TeamInsightsCard />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <HomePageRequestedReviewsCard />
              </Grid>
              <Grid item xs={6}>
                {/* You can build up a query using Github Advanced Search https://github.com/search/advanced */}
                <HomePageYourOpenPullRequestsCard />
              </Grid>
            </Grid>
            {/* Favorites Entities and Jira Metrics */}
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <UsefulLinks />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
