import { componentEntityPage } from "./entity/ComponentEntityPage";
import { userEntityPage } from "./entity/UserEntityPage";
import { systemEntityPage } from "./entity/SystemEntityPage";
import { domainEntityPage } from "./entity/DomainEntityPage";
import { defaultEntityPage } from "./entity/DefaultEntityPage";
import { groupEntityPage } from "./entity/GroupEntityPage";
import { EntitySwitch, isKind } from "../components/catalog";
import { apiEntityPage } from "./entity/ApiEntityPage";
import { resourceEntityPage } from "./entity/ResourceEntityPage";
import { templateEntityPage } from "./entity/TemplateEntityPage";

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind("component")}>
      {componentEntityPage}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind("api")}>{apiEntityPage}</EntitySwitch.Case>
    <EntitySwitch.Case if={isKind("group")}>
      {groupEntityPage}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind("user")}>{userEntityPage}</EntitySwitch.Case>
    <EntitySwitch.Case if={isKind("resource")}>
      {resourceEntityPage}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind("system")}>
      {systemEntityPage}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind("domain")}>
      {domainEntityPage}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind("template")}>
      {templateEntityPage}
    </EntitySwitch.Case>
    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
