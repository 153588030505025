import { useCallback, useEffect, useMemo, useState } from "react";
import { Content, ErrorPanel, Header, Page } from "@backstage/core-components";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { ResizableBox } from "react-resizable";

import {
  ScaffolderTaskOutput,
  scaffolderApiRef,
  useTaskEventStream,
} from "@backstage/plugin-scaffolder-react";
import { useAnalytics, useApi, useRouteRef } from "@backstage/core-plugin-api";
import qs from "qs";
import { ContextMenu } from "./ContextMenu";
import {
  DefaultTemplateOutputs,
  TaskLogStream,
  TaskSteps,
} from "@backstage/plugin-scaffolder-react/alpha";
import { useAsync } from "@react-hookz/web";
import { usePermission } from "@backstage/plugin-permission-react";
import {
  taskCancelPermission,
  taskReadPermission,
  taskCreatePermission,
} from "@backstage/plugin-scaffolder-common/alpha";
import { useTranslationRef } from "@backstage/core-plugin-api/alpha";
import { scaffolderTranslationRef } from "./translation";
import { scaffolderPlugin } from "@backstage/plugin-scaffolder";

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  buttonBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  logsVisibilityButton: {
    marginRight: theme.spacing(1),
  },
}));

/**
 * @public
 */
export const OngoingTask = (props: {
  TemplateOutputsComponent?: React.ComponentType<{
    output?: ScaffolderTaskOutput;
  }>;
}) => {
  // todo(blam): check that task Id actually exists, and that it's valid. otherwise redirect to something more useful.
  const { taskId } = useParams();
  const templateRouteRef = useRouteRef(
    scaffolderPlugin.routes.selectedTemplate,
  );
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const scaffolderApi = useApi(scaffolderApiRef);
  const taskStream = useTaskEventStream(taskId!);
  const classes = useStyles();
  const steps = useMemo(
    () =>
      taskStream.task?.spec.steps.map(step => ({
        ...step,
        ...taskStream?.steps?.[step.id],
      })) ?? [],
    [taskStream],
  );
  const { t } = useTranslationRef(scaffolderTranslationRef);

  const [logsVisible, setLogVisibleState] = useState(false);
  const [buttonBarVisible, setButtonBarVisibleState] = useState(true);

  // Used dummy string value for `resourceRef` since `allowed` field will always return `false` if `resourceRef` is `undefined`
  const { allowed: canCancelTask } = usePermission({
    permission: taskCancelPermission,
  });

  const { allowed: canReadTask } = usePermission({
    permission: taskReadPermission,
  });

  const { allowed: canCreateTask } = usePermission({
    permission: taskCreatePermission,
  });

  // Start Over endpoint requires user to have both read (to grab parameters) and create (to create new task) permissions
  const canStartOver = canReadTask && canCreateTask;

  useEffect(() => {
    if (taskStream.error) {
      setLogVisibleState(true);
    }
  }, [taskStream.error]);

  useEffect(() => {
    if (taskStream.completed && !taskStream.error) {
      setButtonBarVisibleState(false);
    }
  }, [taskStream.error, taskStream.completed]);

  const activeStep = useMemo(() => {
    for (let i = steps.length - 1; i >= 0; i--) {
      if (steps[i].status !== "open") {
        return i;
      }
    }

    return 0;
  }, [steps]);

  const startOver = useCallback(() => {
    const { namespace, name } =
      taskStream.task?.spec.templateInfo?.entity?.metadata ?? {};

    const formData = taskStream.task?.spec.parameters ?? {};

    if (!namespace || !name) {
      return;
    }

    analytics.captureEvent("click", "Task has been started over");

    navigate({
      pathname: templateRouteRef({
        namespace,
        templateName: name,
      }),
      search: `?${qs.stringify({ formData: JSON.stringify(formData) })}`,
    });
  }, [
    analytics,
    navigate,
    taskStream.task?.spec.parameters,
    taskStream.task?.spec.templateInfo?.entity?.metadata,
    templateRouteRef,
  ]);

  const [{ status: cancelStatus }, { execute: triggerCancel }] = useAsync(
    async () => {
      if (taskId) {
        analytics.captureEvent("cancelled", "Template has been cancelled");
        await scaffolderApi.cancelTask(taskId);
      }
    },
  );

  const Outputs = props.TemplateOutputsComponent ?? DefaultTemplateOutputs;

  const templateName =
    taskStream.task?.spec.templateInfo?.entity?.metadata.name || "";

  const cancelEnabled = !(taskStream.cancelled || taskStream.completed);

  return (
    <Page themeId="website">
      <Header
        pageTitleOverride={
          templateName
            ? t("ongoingTask.pageTitle.hasTemplateName", { templateName })
            : t("ongoingTask.pageTitle.noTemplateName")
        }
        title={
          <div>
            {t("ongoingTask.title")} <code>{templateName}</code>
          </div>
        }
        subtitle={t("ongoingTask.subtitle", { taskId: taskId as string })}
      >
        <ContextMenu
          cancelEnabled={cancelEnabled}
          logsVisible={logsVisible}
          buttonBarVisible={buttonBarVisible}
          onStartOver={startOver}
          onToggleLogs={setLogVisibleState}
          onToggleButtonBar={setButtonBarVisibleState}
          taskId={taskId}
        />
      </Header>
      <Content className={classes.contentWrapper}>
        {taskStream.error ? (
          <Box paddingBottom={2}>
            <ErrorPanel
              error={taskStream.error}
              titleFormat="markdown"
              title={taskStream.error.message}
            />
          </Box>
        ) : null}

        <Box paddingBottom={2}>
          <TaskSteps
            steps={steps}
            activeStep={activeStep}
            isComplete={taskStream.completed}
            isError={Boolean(taskStream.error)}
          />
        </Box>

        <Outputs output={taskStream.output} />

        {buttonBarVisible ? (
          <Box paddingBottom={2}>
            <Paper>
              <Box padding={2}>
                <div className={classes.buttonBar}>
                  <Button
                    className={classes.cancelButton}
                    disabled={
                      !cancelEnabled ||
                      cancelStatus !== "not-executed" ||
                      !canCancelTask
                    }
                    onClick={triggerCancel}
                    data-testid="cancel-button"
                  >
                    {t("ongoingTask.cancelButtonTitle")}
                  </Button>
                  <Button
                    className={classes.logsVisibilityButton}
                    color="primary"
                    variant="outlined"
                    onClick={() => setLogVisibleState(!logsVisible)}
                  >
                    {logsVisible
                      ? t("ongoingTask.hideLogsButtonTitle")
                      : t("ongoingTask.showLogsButtonTitle")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={cancelEnabled || !canStartOver}
                    onClick={startOver}
                    data-testid="start-over-button"
                  >
                    {t("ongoingTask.startOverButtonTitle")}
                  </Button>
                </div>
              </Box>
            </Paper>
          </Box>
        ) : null}

        {logsVisible ? (
          <ResizableBox height={240} minConstraints={[0, 160]} axis="y">
            <Paper style={{ height: "100%" }}>
              <Box padding={2} height="100%">
                <TaskLogStream logs={taskStream.stepLogs} />
              </Box>
            </Paper>
          </ResizableBox>
        ) : null}
      </Content>
    </Page>
  );
};
