import { apiDocsPlugin } from "@backstage/plugin-api-docs";
import { catalogPlugin } from "./components/catalog";
import { catalogImportPlugin } from "@backstage/plugin-catalog-import";
import { scaffolderPlugin } from "@backstage/plugin-scaffolder";
import { orgPlugin } from "@backstage/plugin-org";
import { techdocsPlugin } from "@backstage/plugin-techdocs";
import { apis } from "./apis";
import { Root } from "./components/layout/Root";

import { AlertDisplay, OAuthRequestDialog } from "@backstage/core-components";
import { createApp } from "@backstage/app-defaults";
import { AppRouter } from "@backstage/core-app-api";
import type { IconComponent, IdentityApi } from "@backstage/core-plugin-api";
import {
  discoveryApiRef,
  githubAuthApiRef,
  useApi,
} from "@backstage/core-plugin-api";
import { setTokenCookie } from "./cookieAuth";
import { gitlabAuthApiRef } from "@backstage/core-plugin-api";

import { CustomSignInPage } from "./pages/LoginPage";
import LightIcon from "@material-ui/icons/WbSunny";
import DarkIcon from "@material-ui/icons/WbCloudy";
import { ws10Light } from "./theme/ws10Theme-light";
import { ws10Dark } from "./theme/ws10Theme-dark";
import { UnifiedThemeProvider } from "@backstage/theme";
import { FloatingButton } from "./components/layout/FloatingButton";
import GitHubIcon from "@mui/icons-material/GitHub";
import WbCloudyIcon from "@mui/icons-material/WbCloudy";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import { routes } from "./Router";

const app = createApp({
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <CustomSignInPage
          {...props}
          providers={[
            {
              id: "gitlab-auth-provider",
              title: "GitLab",
              message: "Sign in using GitLab",
              apiRef: gitlabAuthApiRef,
            },
            {
              id: "github-auth-provider",
              title: "GitHub",
              message: "Sign in using GitHub",
              apiRef: githubAuthApiRef,
            },
          ]}
          onSignInSuccess={async (identityApi: IdentityApi) => {
            setTokenCookie(
              await discoveryApi.getBaseUrl("cookie"),
              identityApi,
            );

            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: "vf-theme-light",
      title: "VF-Light",
      variant: "light",
      icon: <LightIcon />,

      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={ws10Light}>
          {children}
        </UnifiedThemeProvider>
      ),
    },
    {
      id: "vf-theme-dark",
      title: "VF-Dark",
      variant: "dark",
      icon: <DarkIcon />,

      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={ws10Dark}>{children}</UnifiedThemeProvider>
      ),
    },
  ],
  icons: {
    "kind:component": GitHubIcon as IconComponent,
    "kind:resource": WbCloudyIcon as IconComponent,
    "kind:system": WysiwygIcon as IconComponent,
  },
});

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />

    <AppRouter>
      <Root>
        {routes}
        <FloatingButton /> {/* Support Floating Button */}
      </Root>
    </AppRouter>
  </>,
);
