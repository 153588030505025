import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

interface ComponentCardProps {
  provider: string;
  imagePath: string;
  title: string;
  description?: string | null;
  disabled?: boolean;
  onClick: () => void;
}

export const ComponentCard = (props: ComponentCardProps) => {
  return (
    <Card variant="outlined" sx={{ m: 1, minHeight: "260px", width: "360px" }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            width: "100%",
            height: "100%",
            borderBottom: "1px solid #e0e0e0",
            flex: 1,
          }}
        >
          <CardMedia
            component="img"
            alt={props.imagePath}
            sx={{
              height: 20,
              width: 40,
              objectFit: "contain",
              mr: 1,
            }}
            image={props.imagePath}
            title={props.title}
          />
          <Typography variant="h6" fontWeight={500} color="primary">
            {props.title}
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 3,
          }}
        >
          <Typography variant="body2" p={1} mt={2} color="primary">
            {props.description}{" "}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            paddingTop: "10px",
            justifySelf: "flex-end",
            flex: 1,
          }}
        >
          <Typography variant="subtitle2" color="primary">
            {props.provider}
          </Typography>
          <Button variant="contained" color="primary" onClick={props.onClick}>
            Create
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
