import { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Box,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import Slider from "react-slick"; // Import react-slick slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LinkIcon from "@mui/icons-material/Link";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"; // Import icons for arrows
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useStyles from "./UsefullLinksStyle";

// Custom Next Arrow Component
const NextArrow = (props: { onClick: any }) => {
  const { onClick } = props;
  const classes = useStyles();
  return (
    <IconButton
      className={`${classes.arrow} ${classes.nextArrow}`}
      onClick={onClick}
    >
      <ArrowForwardIosIcon />
    </IconButton>
  );
};

// Custom Previous Arrow Component
const PrevArrow = (props: { onClick: any }) => {
  const { onClick } = props;
  const classes = useStyles();
  return (
    <IconButton
      className={`${classes.arrow} ${classes.prevArrow}`}
      onClick={onClick}
    >
      <ArrowBackIosIcon />
    </IconButton>
  );
};

const linksData = [
  {
    name: "GitHub",
    logo: "/svg/github.svg",
    url: "https://github.com/enterprises/vodafone-group",
  },
  {
    name: "GitLab",
    logo: "/svg/gitlab.svg",
    url: "https://gitlab-ucc.tools.aws.vodafone.com/",
  },
  {
    name: "SonarQube",
    logo: "/svg/sonarqube.svg",
    url: "https://sonarqube.vodafone.com/",
  },
  {
    name: "Datadog",
    logo: "/svg/datadog.svg",
    url: "https://app.datadoghq.eu/account/login",
  },
  {
    name: "Mend",
    logo: "/png/mend.png",
    url: "https://saas-eu.whitesourcesoftware.com/Wss/WSS.html",
  },
  {
    name: "Confluence",
    logo: "/svg/confluence.svg",
    url: "https://confluence.tools.aws.vodafone.com/",
  },
  {
    name: "Jira",
    logo: "/svg/jira.svg",
    url: "https://jira.tools.aws.vodafone.com/",
  },
  {
    name: "Mongo",
    logo: "/svg/mongo.svg",
    url: "https://account.mongodb.com/",
  },
  {
    name: "JFrog",
    logo: "/svg/jfrog.svg",
    url: "https://artifactory.vodafone.com/",
  },
  {
    name: "AWS",
    logo: "/svg/aws.svg",
    url: "https://myapps.microsoft.com/signin/78f5464b-e016-4fdb-9478-ba285b87fb8e?tenantId=68283f3b-8487-4c86-adb3-a5228f18b893",
  },
  {
    name: "Jenkins",
    logo: "/svg/jenkins.svg",
    url: "https://build.tools.aws.vodafone.com/",
  },
  {
    name: "PCS",
    logo: "svg/vf-icon.svg",
    url: "https://portal.pcs.vodafone.com/",
  },
  { name: "Azure", logo: "/svg/azure.svg", url: "https://portal.azure.com/" },
  {
    name: "SourceWeb",
    logo: "/svg/sourceweb.svg",
    url: "https://github.vodafone.com/pages/Vodafone-Digital-Universe/source-web/index.html",
  },
];

export const UsefulLinks = () => {
  const classes = useStyles();
  const [isAllowClick, setisAllowClick] = useState(true);

  // Slider settings for slick slider
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 14, // Default number of slides to show on large screens
    slidesToScroll: 0, // Scroll by one slide at a time
    nextArrow: <NextArrow onClick={undefined} />, // Custom next arrow
    prevArrow: <PrevArrow onClick={undefined} />, // Custom previous arrow
    responsive: [
      {
        breakpoint: 1200, // at 1200px and below
        settings: {
          slidesToShow: 8, // Show 8 slides on large tablets/small desktops
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 992, // at 992px and below
        settings: {
          slidesToShow: 6, // Show 6 slides on tablets
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 768, // at 768px and below
        settings: {
          slidesToShow: 4, // Show 4 slides on mobile landscape
          slidesToScroll: 4,
        },
      },
    ],
  };

  const handleClick = (url: string) => {
    if (isAllowClick) {
      window.open(url, "_blank");
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        {/* Header Section with Icon and Title */}
        <Box display="flex" alignItems="center" mb={2}>
          <LinkIcon className={classes.linkIcon} />
          <Typography variant="h5">Useful Links</Typography>
        </Box>

        {/* Slider Section */}
        <Box className={classes.boxContainer}>
          <Slider
            {...sliderSettings}
            className={classes.sliderContainer}
            beforeChange={() => setisAllowClick(false)}
            afterChange={() => setisAllowClick(true)}
          >
            {linksData.map(link => (
              <div
                key={link.name}
                className={classes.roundedContainer}
                onClick={() => handleClick(link.url)} // Open the link on click
              >
                {/* Wrap the Avatar in a Tooltip to show the name */}
                <Tooltip title={link.name}>
                  <Avatar
                    className={classes.avatar}
                    src={link.logo}
                    alt={link.name}
                  />
                </Tooltip>
              </div>
            ))}
          </Slider>
        </Box>
      </CardContent>
    </Card>
  );
};
