import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  theme => ({
    code: {
      borderRadius: 6,
      margin: theme.spacing(2, 0),
      background:
        theme.palette.type === "dark" ? "#444" : theme.palette.common.white,
    },
  }),
  { name: "PluginCatalogEntityLabelsEmptyState" },
);

export default useStyles;
