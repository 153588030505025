import { Grid } from "@material-ui/core";

import { entityWarningContent } from "./EntityWarningContent";
import { EntityCatalogGraphCard } from "@backstage/plugin-catalog-graph";
import { EntityComponentDetailsCard } from "../../components/catalog";

export const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={12}>
      <EntityComponentDetailsCard variant="gridItem" />
    </Grid>
    <Grid item md={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
  </Grid>
);
