import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  logo: {
    width: "auto",
    height: 50,
  },
});

export default useStyles;
