import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  infoCard: {
    marginBottom: theme.spacing(3),
    "& + .MuiAlert-root": {
      marginTop: theme.spacing(3),
    },
    "& .MuiCardContent-root": {
      padding: theme.spacing(2, 1, 2, 2),
    },
    "& td": {
      whiteSpace: "normal",
    },
  },
}));

export default useStyles;
