import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  list: {
    backgroundColor: theme.palette.mode === "dark" ? "#444444" : "#000000",
  },
  hover: {
    color: "#FFFFFF",
    "&:hover": {
      background:
        theme.palette.navigation.navItem?.hoverBackground || "#404040",
      cursor: "pointer",
    },
  },
}));

export default useStyles;
