import { EntityLayout } from "../../components/catalog";

import { overviewContent } from "../content/OverviewContent";
import { selfServiceContent } from "../content/SelfServiceContent";

const titleWithAvatar = (src: string, text: string) => (
  <span style={{ display: "flex", alignItems: "center" }}>
    <img src={src} style={{ marginRight: 8, width: 18, height: 18 }} />
    {text}
  </span>
);

export const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      path="/infra-self-service"
      title={titleWithAvatar("/svg/selfservice.svg", "SelfService")}
    >
      {selfServiceContent}
    </EntityLayout.Route>
  </EntityLayout>
);
