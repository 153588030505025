// ScoreBadge.tsx

import clsx from "clsx";
import useStyles from "./ScoreBadgeStyles";

interface ScoreBadgeProps {
  score: number;
  size?: "small" | "medium" | "large";
}

export const ScoreBadge: React.FC<ScoreBadgeProps> = ({
  score,
  size = "medium",
}) => {
  const classes = useStyles();

  const getScoreClass = () => {
    if (score < 50) return classes.lowScore;
    if (score < 75) return classes.mediumScore;
    return classes.highScore;
  };

  return (
    <span className={clsx(classes.badge, getScoreClass(), classes[size])}>
      {score}
    </span>
  );
};

export default ScoreBadge;
