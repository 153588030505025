import {
  GetFilePathFromUrl,
  GetHeadPipelineId,
  GetProjectIdFromName,
  GetProjectNameFromUrl,
  MergeMergeRequest,
} from "./services/gitlab";
import { GenerateIacTargetPath } from "./services/iacrepo";
import mustache from "mustache";
import { RenderHcl, TransformVars } from "./services/templateresolver";
import _ from "lodash";

export const GetTargetPathFromContext = async (
  resourceCreationProps: any,
  catalogApi: any,
  variableValues: any,
) => {
  console.log("resourceCreationProps: ", resourceCreationProps);
  console.log("variableValues: ", variableValues);

  const filePath = GetFilePathFromUrl(
    resourceCreationProps.parentEntity.metadata.annotations[
      "backstage.io/view-url"
    ],
  );
  console.log("filePath: ", filePath);

  const basePath = await GenerateIacTargetPath(
    filePath,
    resourceCreationProps.resource.spec.self_service.iac_repo_placement
      .match_path_upto,
    catalogApi,
  );
  console.log("basePath: ", basePath);

  const subPathTemplate =
    resourceCreationProps.resource.spec.self_service.iac_repo_placement.subpath;
  console.log("subPathTemplate: ", subPathTemplate);

  const subPath = mustache.render(subPathTemplate, {
    var: variableValues,
    ...resourceCreationProps.resource,
  });
  console.log("subPath: ", subPath);

  const pathBits = basePath.split("/");
  console.log("pathBits: ", pathBits);
  pathBits.push(subPath);

  return pathBits.join("/");
};

export const GetProjectInfo = async (
  resourceCreationProps: any,
  gitlab: any,
) => {
  const parentEntityGitlabUrl =
    resourceCreationProps.parentEntity.metadata.annotations[
      "backstage.io/view-url"
    ];
  const projectName = GetProjectNameFromUrl(parentEntityGitlabUrl);
  const projectId = await GetProjectIdFromName(gitlab, projectName);

  return { name: projectName, id: projectId };
};

export const ValidateRepo = async (catalogApi: any, projectInfo: any) => {
  const projectAsCodeRepo = (
    await catalogApi.getEntities({
      filter: {
        "metadata.name": projectInfo.current.name,
        "spec.type": "code-repository",
      },
    })
  ).items[0];

  const isProjectValidCodeRepo =
    projectAsCodeRepo &&
    projectAsCodeRepo.metadata.name === projectInfo.current.name;
  if (!isProjectValidCodeRepo) {
    throw new Error(
      `Code repository name ${projectInfo.current.name} is invalid`,
    );
  }
};

export const RenderFiles = async (
  fileName: any,
  resourceCreationProps: any,
  variableValues: any,
) => {
  console.log("fileName: ", fileName);
  const fileExtension = fileName.split(".").pop();
  let fileContent = "";
  let renderedFilename = fileName;
  switch (fileExtension) {
    case "json":
      fileContent = (function (
        userSuppliedVariables: any,
        selfServiceVariables: any,
      ) {
        console.log("selfServiceVariables: ", selfServiceVariables);
        console.log(
          "keys to omit: ",
          selfServiceVariables.map((v: any) => v.variable_name),
        );
        const output = _.omit(
          userSuppliedVariables,
          selfServiceVariables.map((v: any) => v.variable_name),
        );
        return JSON.stringify(output, null, 2);
      })(variableValues, resourceCreationProps.selfServiceVariables);

      renderedFilename = mustache.render(fileName, {
        var: variableValues,
        ...resourceCreationProps.resource,
      });
      break;
    case "hcl":
      fileContent = RenderHcl(resourceCreationProps.resource.metadata.name, {
        inputVars: TransformVars(
          variableValues,
          resourceCreationProps.terraformVariables,
        ),
      } as any);
      break;
    default:
      throw new Error(`File extension ${fileExtension} not supported`);
  }

  return [fileContent, renderedFilename];
};

export const MergeMr = async (gitlab: any, projectId: number, mrId: number) => {
  const mrStatus = await MergeMergeRequest(gitlab, projectId, mrId, 600);
  console.log("Merge merge request returned with: ", mrStatus);

  if (mrStatus.status !== "merged") {
    throw new Error(`Merge request: ${mrStatus.link} failed`);
  }

  const pipelineAfterMerge = await GetHeadPipelineId(
    gitlab,
    projectId,
    mrId,
    600,
  );
  console.log("Get head pipeline id retuned: ", pipelineAfterMerge);

  if (pipelineAfterMerge.pipelineStatus !== "success") {
    throw new Error(`Pipeline: ${pipelineAfterMerge.pipelineLink} failed`);
  }
};
