import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  markdown: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    paddingRight: "32px",
    paddingLeft: "32px",

    "& h1": {
      borderBottom: `2px solid ${theme.palette.divider}`,
      paddingBottom: theme.spacing(1),
      color: theme.palette.primary.main,
    },

    "& h2": {
      fontSize: "1.2rem",
      marginTop: theme.spacing(4),
    },

    "& p": {
      fontSize: "0.875rem",
    },

    "& code": {
      fontSize: "0.75rem",
      backgroundColor: theme.palette.type === "dark" ? "#3c3c3c" : "#f6f8fa",
      color: theme.palette.type === "dark" ? "#f8f8f2" : "#333",
      borderRadius: 4,
      padding: "2px 4px",
    },

    "& pre": {
      backgroundColor: theme.palette.type === "dark" ? "#3c3c3c" : "#f6f8fa",
      color: theme.palette.type === "dark" ? "#f8f8f2" : "#333",
      padding: theme.spacing(2),
      borderRadius: 4,
      overflowX: "auto",
    },

    "& a": {
      color: "#0960DA",
      textDecoration: "none",

      "&:hover": {
        textDecoration: "underline",
      },
    },

    "& ul": {
      paddingLeft: "24px",
    },

    "& span.": {
      color: "#408080",
    },

    "& blockquote": {
      borderLeft: `4px solid ${theme.palette.divider}`,
      paddingLeft: theme.spacing(2),
      marginLeft: "0",
      color:
        theme.palette.type === "dark"
          ? "#b0bec5"
          : theme.palette.text.secondary,
      fontStyle: "italic",
      backgroundColor: theme.palette.type === "dark" ? "#3c3c3c" : "#f6f8fa",
      borderRadius: 4,
    },
    "& img": {
      maxWidth: "100%",
    },
  },
}));

export default useStyles;
