import { useEntity } from "@backstage/plugin-catalog-react";
import { useApi } from "@backstage/core-plugin-api";
import { scmIntegrationsApiRef } from "@backstage/integration-react";
import {
  parseLocationRef,
  ANNOTATION_LOCATION,
  ANNOTATION_SOURCE_LOCATION,
} from "@backstage/catalog-model";

export const GITLAB_ANNOTATION_PROJECT_ID = "gitlab.com/project-id";
export const GITLAB_ANNOTATION_PROJECT_SLUG = "gitlab.com/project-slug";
export const GITLAB_ANNOTATION_INSTANCE = "gitlab.com/instance";
export const GITLAB_ANNOTATION_CODEOWNERS_PATH = "gitlab.com/codeowners-path";
export const GITLAB_ANNOTATION_README_PATH = "gitlab.com/readme-path";
const defaultGitlabIntegration = {
  hostname: "gitlab.com",
  baseUrl: "https://gitlab.com/api/v4",
};

export const useEntityGitlabScmIntegration = () => {
  const { entity } = useEntity();
  const integrations = useApi(scmIntegrationsApiRef);
  if (!entity) {
    return defaultGitlabIntegration;
  }

  let location = entity.metadata.annotations?.[ANNOTATION_SOURCE_LOCATION];

  if (!location) {
    location = entity.metadata.annotations?.[ANNOTATION_LOCATION];
  }

  const { target } = parseLocationRef(location || "");

  const scm = integrations.gitlab.byUrl(target);
  if (scm) {
    return {
      hostname: scm.config.host,
      baseUrl: scm.config.apiBaseUrl,
    };
  }
  return defaultGitlabIntegration;
};

export const gitlabProjectId = () => {
  const { entity } = useEntity();

  const project_id =
    entity.metadata.annotations?.[GITLAB_ANNOTATION_PROJECT_ID] ?? "";

  return project_id;
};

export const gitlabProjectSlug = () => {
  const { entity } = useEntity();

  const project_slug =
    entity.metadata.annotations?.[GITLAB_ANNOTATION_PROJECT_SLUG] ?? "";

  return project_slug;
};

export const gitlabInstance = () => {
  const { entity } = useEntity();

  const instance =
    entity.metadata.annotations?.[GITLAB_ANNOTATION_INSTANCE] ?? "";

  return instance;
};

export const gitlabCodeOwnerPath = () => {
  const { entity } = useEntity();

  const codeowners_path =
    entity.metadata.annotations?.[GITLAB_ANNOTATION_CODEOWNERS_PATH] ?? "";

  return codeowners_path;
};

export const gitlabReadmePath = () => {
  const { entity } = useEntity();

  const readme_path =
    entity.metadata.annotations?.[GITLAB_ANNOTATION_README_PATH] ?? "";

  return readme_path;
};
