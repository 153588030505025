//todo - needs to move to backend, as the file content template should be read form github using the authentication provided by the integrations

import mustache from "mustache";

const HCL_TEMPLATES: Map<string, string> = new Map([
  [
    "eks-namespace",
    `include "root" {
path = find_in_parent_folders()
}

include "eks_namespaces" {
  path           = format("%s/_envcommon/modules/eks-namespaces.hcl", dirname(find_in_parent_folders()))
  expose         = true
  merge_strategy = "deep"
}

inputs = {
  eks_cluster_name = dependency.eks_control_plane.outputs.eks_cluster_name
{{#inputVars}}
  {{key}} = "{{value}}"
{{/inputVars}}
}
`,
  ],
  [
    "kafka-topic",
    `{
"config": {
  {{#inputVars}}
    {{key}} = "{{value}}"
  {{/inputVars}}
},
"partitions": "1"
}
`,
  ],
]);

export const TransformVars = (
  userSuppliedVariables: { [key: string]: unknown },
  terraformVariables: { variable_name: string }[],
) => {
  return Object.keys(userSuppliedVariables)
    .filter(k => {
      return terraformVariables.map(v => v.variable_name).includes(k);
    })
    .map(k => {
      return { key: k, value: userSuppliedVariables[k] };
    });
};

export const RenderHcl = (
  templateName: string,
  templateVars: string,
): string => {
  if (!HCL_TEMPLATES.has(templateName)) {
    throw new Error(`Template ${templateName} not found`);
  }

  console.log("templateVars: ", templateVars);

  // const teplateVarsFiltered = Object.entries(templateVars).filter(
  //   ([_, v]) => v !== undefined,
  // );

  const template = mustache.render(
    HCL_TEMPLATES.get(templateName) as string,
    templateVars,
  );
  return template;
};
