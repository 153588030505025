import {
  EntityComponentDetailsCard,
  EntityLayout,
  EntitySwitch,
} from "../../components/catalog";
import { Grid } from "@material-ui/core";

import { entityWarningContent } from "../content/EntityWarningContent";
import {
  EntityMembersListCard,
  EntityOwnershipCard,
} from "@backstage/plugin-org";
import { EntityJiraOverviewCard, isJiraAvailable } from "../../components/jira";

export const groupEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={12}>
          <EntityComponentDetailsCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={12}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={12}>
          <EntityMembersListCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/jira-dashboard" title="Jira Dashboard">
      <EntitySwitch>
        <EntitySwitch.Case if={isJiraAvailable}>
          <Grid item md={12}>
            <EntityJiraOverviewCard />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </EntityLayout.Route>
  </EntityLayout>
);
