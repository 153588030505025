import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    subtitles: {
      whiteSpace: "nowrap",
    },
  }),
);

export default useStyles;
