import useStyles from "./LogoFullWhiteStyles";

const LogoFullWhite = () => {
  const classes = useStyles();
  return (
    <img
      src="/png/vf-logo-white.png"
      alt="Vodafone Logo"
      className={classes.logo}
    />
  );
};

export default LogoFullWhite;
