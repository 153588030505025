import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import {
  Content,
  ContentHeader,
  SupportButton,
} from "@backstage/core-components";
import { useApi } from "@backstage/core-plugin-api";
import { catalogApiRef } from "@backstage/plugin-catalog-react";
import { ComponentCard } from "./ComponentCard";
import { ResourceCreationDialog } from "./ResourceCreationDialog/ResourceCreationDialog";

export const InfraSelfService = (entityWrapper: any) => {
  const catalogApi = useApi(catalogApiRef);

  const [availableResources, setAvailableResources] = useState([] as any[]);
  const [resourceDialogOpen, setResourceDialogOpen] = useState(false);

  useEffect(() => {
    const getAvailableResources = async () => {
      const entityFilter: { [key: string]: any } = {
        "spec.type": "selfservice",
      };
      if (entityWrapper.entity) {
        entityFilter["spec.self_service.resource_depends_on"] = [
          entityWrapper.entity.spec.type,
        ] as any;
      }
      const resources = await catalogApi.getEntities({ filter: entityFilter });
      setAvailableResources(resources.items);
    };

    getAvailableResources();
  }, [catalogApi]);

  return (
    <Content>
      <ContentHeader title="Infrastrucutre as Code deployments">
        <SupportButton>
          Enables the logged in user to start pipelines that will deploy Cloud
          infrastructure via IAC.
        </SupportButton>
      </ContentHeader>
      {/* {entityWrapper.entity && (
        console.log(JSON.stringify(entityWrapper, null, 2))
      )} */}
      <Grid container spacing={3} my={1}>
        {availableResources.length > 0 &&
          availableResources.map((resource: any) => (
            <div key={resource.metadata.uid}>
              <ResourceCreationDialog
                close={() => setResourceDialogOpen(false)}
                open={resourceDialogOpen}
                terraformVariables={resource.spec.terraform.variables}
                selfServiceVariables={resource.spec.self_service.variables}
                resourceName={resource.metadata.name}
                parentEntity={entityWrapper.entity}
                resource={resource}
                fileName={
                  resource.spec.self_service.iac_repo_placement.filename
                }
                usesParentPipeline={
                  resource.spec.self_service.uses_parent_pipeline
                }
                infraSelfServiceResource={resource}
              />
              <ComponentCard
                provider={resource.spec.self_service.provided_by}
                title={resource.metadata.name}
                description={resource.spec.terraform.description}
                imagePath={resource.metadata.icon_url}
                onClick={async () => {
                  setResourceDialogOpen(true);
                }}
              />
            </div>
          ))}
      </Grid>
    </Content>
  );
};
