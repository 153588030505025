import { EntityLayout } from "../../components/catalog";

import { overviewContent } from "../content/OverviewContent";

export const userEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
  </EntityLayout>
);
