import { useEffect, useReducer, useRef, useState, memo } from "react";

// Material-UI components
import {
  Box,
  Divider,
  Typography,
  Dialog,
  Button,
  CircularProgress,
  styled,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

// Custom components
import {
  CancelPipeline,
  CloseMergeRequest,
  CreateBranch,
  CreateCommit,
  CreateOrUpdateMergeRequest,
  DeleteBranch,
  GetFilePathFromUrl,
  GetHeadPipelineId,
  GetManualJobsWithKeyword,
  PlayJob,
  WaitForConverge,
} from "../services/gitlab";
import {
  GetProjectInfo,
  GetTargetPathFromContext,
  MergeMr,
  RenderFiles,
  ValidateRepo,
} from "../resourceCreation";
import { InputBlock } from "./InputBlock";
import { Disclaimer } from "./Disclamer";
import _ from "lodash";

// APIS
import { catalogApiRef } from "@backstage/plugin-catalog-react";
import { gitlabAuthApiRef, useApi } from "@backstage/core-plugin-api";

// Utilities
import path from "path-browserify";

// Gitlab SDK
import { Gitlab } from "@gitbeaker/rest";

/* If you want to mock Gitlab to just test UI, you can use:

import {gitlabMock } from './mock/gitlab'; 
instead of: 
import { Gitlab } from '@gitbeaker/rest';

And 
const gitlab = gitlabMock;
instead of:
const gitlab = new Gitlab({
  host: 'https://gitlab-ucc.tools.aws.vodafone.com',
  oauthToken: gitlabAccessToken,
});

*/

// Styled Components
const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "auto", // Adjust width as needed
    maxWidth: "lg", // You can specify a maxWidth value like 'sm', 'md', 'lg', etc.
    height: "auto", // Adjust height as needed
    maxHeight: "90vh", // Ensure it doesn't go beyond the viewport height
  },
});

// Type Definitions
type DialogProps = {
  resourceName: string;
  selfServiceVariables: any;
  fileName: string | undefined;
  terraformVariables: any;
  usesParentPipeline: boolean;
  parentEntity: any;
  resource: any;
  infraSelfServiceResource: any;
  open: boolean;
  close: () => void;
};

// State and ButtonLabel Enums
type DialogState =
  | "self-service-input"
  | "terraform-input"
  | "validate"
  | "deploy";
type DialogButtonLabel = "Next" | "Validate" | "Deploy" | "Close";

// Main Component
export const ResourceCreationDialog = (props: DialogProps) => {
  // Using APIs
  const catalogApi = useApi(catalogApiRef);
  const gitlabAuthApi = useApi(gitlabAuthApiRef);

  // State Variables
  const [activeDialogState, setDialogState] =
    useState<DialogState>("self-service-input");
  const [dialogButtonLabel, setDialogButtonLabel] =
    useState<DialogButtonLabel>("Next");
  const [variableValues, setVariableValues] = useState<Map<string, string>>(
    new Map(),
  );
  const [missingRequiredValues, setMissingRequiredValues] = useState<string[]>(
    [],
  );
  const [gitlabAccessToken, setGitlabAccessToken] = useState<string>("");
  const [branchName, setBranchName] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [isCancelled, setIsCancelled] = useState<boolean>(false);

  // Ref Variables
  const isNormalFlowInProgress = useRef(true);
  const isRollbackFlowInProgress = useRef(false);

  // References for storing intermediate data
  const targetPath = useRef<string>("");
  const projectInfo = useRef<{ name: string; id: number }>({ name: "", id: 0 });
  const stepsDone = useRef<Set<string>>(new Set());
  const mrInfo = useRef<{ id: number; link: string }>({ id: 0, link: "" });
  const jobKey = useRef<string>("");
  const planJob = useRef<any>(null);

  // Gitlab token
  const gitlab = new Gitlab({
    host: "https://gitlab-ucc.tools.aws.vodafone.com",
    oauthToken: gitlabAccessToken,
  });

  // Function to advance the dialog state
  const advanceDialogState = () => {
    switch (activeDialogState) {
      case "self-service-input":
        setDialogState("terraform-input");
        setDialogButtonLabel("Validate");
        setIsButtonDisabled(false);
        break;
      case "terraform-input":
        setDialogState("validate");
        setDialogButtonLabel("Deploy");
        setIsButtonDisabled(true);
        break;
      case "validate":
        setDialogState("deploy");
        setDialogButtonLabel("Close");
        break;
      default:
        break;
    }
  };
  // Function to reset the dialog state
  const resetDialogState = () => {
    setDialogState("self-service-input");
    setDialogButtonLabel("Next");
    setVariableValues({} as any);
    setMissingRequiredValues([]);
    setIsButtonDisabled(false);
  };

  // Function to execute rollback actions
  const executeRollback = async () => {
    if (typeof steps[activeStep].rollback === "function") {
      await steps[activeStep].rollback()();
    }
  };
  // Steps for the dialog process
  const steps = [
    {
      name: "Constructing target path",
      func: () => async () => {
        if (isRollbackFlowInProgress.current) {
          return;
        }
        isNormalFlowInProgress.current = true;
        try {
          targetPath.current = await GetTargetPathFromContext(
            props,
            catalogApi,
            variableValues,
          );
          await stepDelay();
        } finally {
          isNormalFlowInProgress.current = false;
        }
      },
      rollback: () => async () => {
        // No rollback action needed
      },
      autoAdvance: true,
    },
    {
      name: "Retrieving project ID",
      func: () => async () => {
        if (isRollbackFlowInProgress.current) {
          return;
        }
        isNormalFlowInProgress.current = true;
        try {
          projectInfo.current = await GetProjectInfo(props, gitlab);
          await stepDelay();
        } finally {
          isNormalFlowInProgress.current = false;
        }
      },
      rollback: () => async () => {
        // No rollback action needed
      },
      autoAdvance: true,
    },
    {
      name: "Creating branch",
      func: () => async () => {
        if (isRollbackFlowInProgress.current) {
          return;
        }
        isNormalFlowInProgress.current = true;
        try {
          await ValidateRepo(catalogApi, projectInfo);
          await CreateBranch(gitlab, projectInfo.current.id, branchName);
          await stepDelay();
        } finally {
          isNormalFlowInProgress.current = false;
        }
      },
      rollback: () => async () => {
        await DeleteBranch(gitlab, projectInfo.current.id, branchName);
      },
      autoAdvance: true,
    },
    {
      name: "Committing changes",
      func: () => async () => {
        if (isRollbackFlowInProgress.current) {
          return;
        }
        isNormalFlowInProgress.current = true;
        try {
          const fileName = props.fileName || "terragrunt.hcl";
          const [fileContent, renderedFilename] = await RenderFiles(
            fileName,
            props,
            variableValues,
          );
          await CreateCommit(
            gitlab,
            projectInfo.current.id,
            branchName,
            props.resourceName,
            path.join(targetPath.current, renderedFilename),
            fileContent,
          );
          await stepDelay();
        } finally {
          isNormalFlowInProgress.current = false;
        }
      },
      rollback: () => async () => {
        await DeleteBranch(gitlab, projectInfo.current.id, branchName);
      },
      autoAdvance: true,
    },
    {
      name: "Creating merge request",
      func: () => async () => {
        if (isRollbackFlowInProgress.current) {
          return;
        }
        isNormalFlowInProgress.current = true;
        try {
          mrInfo.current = await CreateOrUpdateMergeRequest(
            gitlab,
            projectInfo.current.id,
            branchName,
            "[Selfservice BOT] Creating: " + props.resourceName,
          );
          await stepDelay();
        } finally {
          isNormalFlowInProgress.current = false;
        }
      },
      rollback: () => async () => {
        try {
          const pipeline = await GetHeadPipelineId(
            gitlab,
            projectInfo.current.id,
            mrInfo.current.id,
            100,
            false,
          );
          await CancelPipeline(
            gitlab,
            projectInfo.current.id,
            pipeline.pipelineId,
          );
          console.log(`Pipeline ${pipeline.pipelineId} canceled successfully.`);
          await CloseMergeRequest(
            gitlab,
            projectInfo.current.id,
            mrInfo.current.id,
          );
          await DeleteBranch(gitlab, projectInfo.current.id, branchName);
        } catch (error) {
          console.error(`Failed to rollback MR: ${error}`);
        }
      },
      autoAdvance: true,
    },
    {
      name: "Waiting for pipeline success",
      func: () => async () => {
        if (isRollbackFlowInProgress.current) {
          return;
        }
        isNormalFlowInProgress.current = true;
        try {
          const pipeline = await GetHeadPipelineId(
            gitlab,
            projectInfo.current.id,
            mrInfo.current.id,
            600,
            true,
          );
          if (pipeline.pipelineStatus !== "success") {
            throw new Error(`Pipeline: ${pipeline.pipelineLink} failed`);
          }
          await stepDelay();
          setIsButtonDisabled(false);
        } finally {
          isNormalFlowInProgress.current = false;
        }
      },
      rollback: () => async () => {
        try {
          await CloseMergeRequest(
            gitlab,
            projectInfo.current.id,
            mrInfo.current.id,
          );
          await DeleteBranch(gitlab, projectInfo.current.id, branchName);
        } catch (error) {
          console.error(`Failed to Close MR: ${error}`);
        }
      },
      autoAdvance: false,
    },
    {
      name: "Merge MR",
      func: () => async () => {
        if (isRollbackFlowInProgress.current) {
          return;
        }
        isNormalFlowInProgress.current = true;
        try {
          await MergeMr(gitlab, projectInfo.current.id, mrInfo.current.id);
          await stepDelay();
        } finally {
          isNormalFlowInProgress.current = false;
        }
      },
      rollback: () => async () => {
        // You can't rollback after deploy
      },
      autoAdvance: true,
    },
    {
      name: "Wait for converge jobs",
      func: () => async () => {
        if (isRollbackFlowInProgress.current) {
          return;
        }
        isNormalFlowInProgress.current = true;
        try {
          await WaitForConverge(gitlab, projectInfo.current.id, 600);
          await stepDelay();
        } finally {
          isNormalFlowInProgress.current = false;
        }
      },
      rollback: () => async () => {
        // You can't rollback after deploy
      },
      autoAdvance: true,
    },
    {
      name: "Run plan job",
      func: () => async () => {
        if (isRollbackFlowInProgress.current) {
          return;
        }
        isNormalFlowInProgress.current = true;
        try {
          const parentPath = GetFilePathFromUrl(
            props.parentEntity.metadata.annotations["backstage.io/view-url"],
          ).replace(/\/catalog-info\.yaml$/, "");
          jobKey.current = props.usesParentPipeline
            ? parentPath
            : targetPath.current;

          planJob.current = await GetManualJobsWithKeyword(
            gitlab,
            projectInfo.current.id,
            `${jobKey.current} plan`,
            600,
          );

          await PlayJob(gitlab, projectInfo.current.id, planJob.current.jobId);
          await stepDelay();
        } finally {
          isNormalFlowInProgress.current = false;
        }
      },
      rollback: () => async () => {
        // You can't rollback after deploy
      },
      autoAdvance: true,
    },
    {
      name: "Run apply job",
      func: () => async () => {
        if (isRollbackFlowInProgress.current) {
          return;
        }
        isNormalFlowInProgress.current = true;
        try {
          const applyJob = await GetManualJobsWithKeyword(
            gitlab,
            projectInfo.current.id,
            jobKey.current,
            600,
          );
          await PlayJob(gitlab, projectInfo.current.id, applyJob.jobId);
          console.log("All done");
        } finally {
          isNormalFlowInProgress.current = false;
        }
      },
      rollback: () => async () => {
        // You can't rollback after deploy
      },
      autoAdvance: false,
    },
  ];

  // Reducer to manage step progression
  const [activeStep, incrementStep] = useReducer(
    (state: number, action: "next" | "prev") => {
      if (action === "next") {
        return state + 1;
      } else {
        return state - 1;
      }
    },
    0,
  );

  // Use useEffect to monitor isCancelled and trigger rollback
  useEffect(() => {
    if (isCancelled) {
      resetDialogState();
      setIsCancelled(false);
      props.close();
      isRollbackFlowInProgress.current = true;
      if (isNormalFlowInProgress.current == true) {
        const intervalId = setInterval(() => {
          if (isNormalFlowInProgress.current == false) {
            clearInterval(intervalId);
            executeRollback();
          }
        }, 100);
      } else {
        executeRollback();
      }
    }
  }, [isCancelled]);

  // Fetch GitLab access token and initialize branch name on mount
  useEffect(() => {
    const getGitlaAccessToken = async () => {
      const token = await gitlabAuthApi.getAccessToken("api read_api");
      setGitlabAccessToken(token);
    };

    const timestamp = new Date().toISOString().replace(/[-:T.Z]/g, "");
    setBranchName(["selfservice", timestamp].join("-"));

    getGitlaAccessToken();
  }, []);

  // Step delay function
  const stepDelay = () => {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        resolve();
      }, 600);
    });
  };

  // Status block component to show progress
  type StatusBlockProps = {
    promise: Promise<void>;
  };

  // Memoized functional component to display the status of asynchronous operation
  const StatusBlock = memo((props: StatusBlockProps) => {
    const [status, setStatus] = useState("pending");

    useEffect(() => {
      // Function to handle the asynchronous operation
      const awaitPromise = async () => {
        await props.promise;
        setStatus("done");
        if (steps[activeStep].autoAdvance) {
          incrementStep("next");
        }
        if ([...stepsDone.current.keys()].length !== steps.length - 1) {
          stepsDone.current.add(steps[activeStep].name);
        }
      };

      // Invoke the asynchronous function
      awaitPromise();
    }, []);

    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="space-between"
        mx="20%"
        my={"2px"}
      >
        {[...stepsDone.current.keys()].map((step, i) => {
          return (
            <Box
              key={i}
              display="flex"
              width={"100%"}
              justifyContent="space-between"
            >
              <Typography mb={"2px"} variant="body1" component="div">
                {step}
              </Typography>
              <CheckCircleOutlineIcon color="primary" />
            </Box>
          );
        })}
        {status === "pending" && (
          <Box
            display="flex"
            mt={2}
            p={1}
            width={"100%"}
            justifyContent="space-between"
          >
            <Typography
              mb={"2px"}
              variant="body1"
              fontWeight={"bold"}
              component="div"
              color="primary"
            >
              {steps[activeStep].name}
            </Typography>
            <CircularProgress size={"1.5rem"} thickness={5} />
          </Box>
        )}
      </Box>
    );
  });

  StatusBlock.displayName = "StatusBlock";

  return (
    <div>
      <StyledDialog
        open={props.open}
        onClose={props.close}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: "rgba(0,0,0,0.5)",
            },
          },
        }}
      >
        <Box
          minHeight="250px"
          minWidth="600px"
          p={1}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gap={0.5}
        >
          <Typography variant="h5" component="div" mb={0.5} color="primary">
            {props.resourceName}
          </Typography>
          <Divider sx={{ my: 0.25 }} />
          {activeDialogState === "self-service-input" && (
            <InputBlock
              variables={props.selfServiceVariables}
              setVariableValues={setVariableValues}
              missingRequiredValues={missingRequiredValues}
              variableValues={variableValues}
            />
          )}
          {activeDialogState === "terraform-input" && (
            <InputBlock
              variables={props.terraformVariables}
              setVariableValues={setVariableValues}
              missingRequiredValues={missingRequiredValues}
              variableValues={variableValues}
            />
          )}
          {activeDialogState === "validate" && (
            <Box>
              <StatusBlock promise={steps[activeStep].func()()} />
            </Box>
          )}
          {activeDialogState === "deploy" && (
            <Box>
              <StatusBlock promise={steps[activeStep].func()()} />
            </Box>
          )}
          <Divider sx={{ my: 0.25 }} />
          <Box display="flex" justifyContent="center" mt={0.5}>
            <Disclaimer />
          </Box>
          <Box display="flex" justifyContent="space-around" mt={0.5}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setIsCancelled(true);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                if (
                  activeDialogState === "self-service-input" ||
                  activeDialogState === "terraform-input"
                ) {
                  const misingRequired = (
                    activeDialogState === "self-service-input"
                      ? props.selfServiceVariables
                      : props.terraformVariables
                  ).filter(
                    (variable: any) =>
                      variable.required &&
                      !_.has(variableValues, variable.variable_name),
                  );
                  if (misingRequired.length > 0) {
                    setMissingRequiredValues(misingRequired);
                    return;
                  }
                }

                if (activeDialogState === "validate") {
                  stepsDone.current = new Set<string>();
                  incrementStep("next");
                }

                if (activeDialogState === "deploy") {
                  resetDialogState();
                  props.close();
                }

                advanceDialogState();
              }}
              disabled={isButtonDisabled}
            >
              {dialogButtonLabel}
            </Button>
          </Box>
        </Box>
      </StyledDialog>
    </div>
  );
};
