import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  link: {
    color: "inherit",
    textDecoration: "none",
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0),
  },
  box: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
  boxLink: {
    marginTop: theme.spacing(0),
  },
  title: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
}));

export default useStyles;
