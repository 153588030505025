import { Box, Typography } from "@mui/material";
import _ from "lodash";
import VariableInputField from "../VariableInputField";

type InputBlockProps = {
  variables: any;
  setVariableValues: (values: Map<string, string>) => void;
  missingRequiredValues: string[];
  variableValues: Map<string, string>;
};

// Render input fields for variables
export const InputBlock = ({
  variables,
  setVariableValues,
  missingRequiredValues,
  variableValues,
}: InputBlockProps) => {
  return (
    <Box>
      {variables.map((variable: any, i: number) => {
        const setVariable = (name: string, value: string, parent?: string) => {
          const var_name = parent ? `${parent}.["${name}"]` : name;
          _.set(variableValues, var_name, value);
          setVariableValues({ ...variableValues });
        };
        return (
          <Box key={i}>
            {missingRequiredValues.includes(variable) && (
              <Typography my={-3} variant="caption" color="error">
                This field is required
              </Typography>
            )}
            <VariableInputField
              variable={variable}
              setVariable={setVariable}
              key={i}
            />
          </Box>
        );
      })}
    </Box>
  );
};
