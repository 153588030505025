import { Grid, Box } from "@material-ui/core";
import { Link } from "@backstage/core-components";
import { PeopleCardEntity } from "../PeopleCardEntity";
import { PeopleCardEntityData, PeopleLink } from "../../../../types";
import ArrowIcon from "@material-ui/icons/ArrowForward";
import useStyles from "./PeopleListStyles";

interface PeopleListProps {
  title: string;
  peopleObj: PeopleCardEntityData[];
  deepLink?: PeopleLink;
}

export const PeopleList = ({ title, peopleObj, deepLink }: PeopleListProps) => {
  const classes = useStyles();
  return (
    <>
      <Box
        className={classes.box}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <h2 className={classes.title}>{title}</h2>
        {deepLink && (
          <Box className={classes.boxLink} display="flex" alignItems="center">
            <Link
              className={classes.link}
              to={deepLink.link}
              onClick={deepLink.onClick}
            >
              {deepLink.title}
            </Link>
            <ArrowIcon fontSize="small"></ArrowIcon>
          </Box>
        )}
      </Box>
      <Grid container spacing={1} justifyContent="flex-start">
        {peopleObj?.map((peopleCardEntity: PeopleCardEntityData) => (
          <Grid key={peopleCardEntity.email || peopleCardEntity.name} item>
            <PeopleCardEntity peopleCardEntity={peopleCardEntity} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
