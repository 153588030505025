import {
  UserSettingsAuthProviders,
  UserSettingsIdentityCard,
  UserSettingsProfileCard,
} from "@backstage/plugin-user-settings";
import { Box, Grid, Paper } from "@material-ui/core";

export const UserSettingsGeneral = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container direction="row-reverse" spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper square={false}>
            <UserSettingsAuthProviders />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper square={false}>
            <UserSettingsProfileCard />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper square={false}>
            <UserSettingsIdentityCard />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
