import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    overflow: "hidden",
    height: "100%",
  },
  content: {
    overflow: "hidden",
  },
  navbar: {
    overflow: "visible",
  },
});

export default useStyles;
