import { Box, Typography, styled } from "@mui/material";

const DisclaimerContainer = styled(Box)({
  backgroundColor: "#fff3cd",
  color: "#856404",
  border: "1px solid #ffeeba",
  padding: "2px 5px",
  borderRadius: "5px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  maxWidth: "600px",
  textAlign: "center",
  margin: "5px auto",
});

const DisclaimerText = styled(Typography)({
  margin: 0,
  fontSize: "1.1em",
});

export const Disclaimer = () => {
  return (
    <DisclaimerContainer>
      <DisclaimerText variant="h4">
        *After clicking the <span style={{ color: "red" }}>Deploy</span> button,
        there is no way to go back.
      </DisclaimerText>
    </DisclaimerContainer>
  );
};
