import {
  createUnifiedTheme,
  defaultTypography,
  genPageTheme,
  palettes,
} from "@backstage/theme";

const vodafoneBold = {
  fontFamily: "Vodafone",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 700,
  src: "url('https://cdn.vodafone.co.uk/assets/fonts/vodafone-bold.woff') format('woff')",
};
const vodafoneExtraBold = {
  fontFamily: "Vodafone",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 1000,
  src: "url('https://cdn.vodafone.co.uk/assets/fonts/vodafone-extra-bold.woff') format('woff')",
};
const vodafoneLight = {
  fontFamily: "Vodafone",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 200,

  src: "url('https://cdn.vodafone.co.uk/assets/fonts/vodafone-light.woff') format('woff')",
};
const vodafoneRegular = {
  fontFamily: "Vodafone",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,

  src: "url('https://cdn.vodafone.co.uk/assets/fonts/vodafone-regular.woff') format('woff')",
};

export const ws10Dark = createUnifiedTheme({
  fontFamily: "Vodafone",
  pageTheme: {
    home: genPageTheme({ colors: ["#820000", "#e60000"], shape: "none" }),
    documentation: genPageTheme({
      colors: ["#820000", "#e60000"],
      shape: "none",
    }),
    tool: genPageTheme({ colors: ["#820000", "#e60000"], shape: "none" }),
    service: genPageTheme({
      colors: ["#820000", "#e60000"],
      shape: "none",
    }),
    website: genPageTheme({
      colors: ["#820000", "#e60000"],
      shape: "none",
    }),
    library: genPageTheme({
      colors: ["#820000", "#e60000"],
      shape: "none",
    }),
    other: genPageTheme({ colors: ["#820000", "#e60000"], shape: "none" }),
    app: genPageTheme({ colors: ["#820000", "#e60000"], shape: "none" }),
    apis: genPageTheme({ colors: ["#820000", "#e60000"], shape: "none" }),
  },
  palette: {
    ...palettes.dark,
    mode: "light",
    primary: {
      main: "#FFFFFF",
      light: "#C30000",
      dark: "#A10000",
    },
    secondary: {
      main: "#00697C",
      light: "#005969",
      dark: "#004A57",
    },
    error: {
      main: "#BD0000",
      light: "#BD0000",
      dark: "#BD0000",
    },
    success: {
      main: "#008A00",
      light: "#007500",
      dark: "#006100",
    },
    info: {
      main: "#005EA5",
      light: "#005EA5",
      dark: "#005EA5",
    },
    warning: {
      main: "#EB6100",
      light: "#EB6100",
      dark: "#EB6100",
    },
    navigation: {
      //Sidebar settings
      background: "#252525",
      color: "#FFFFFF",
      indicator: "none",
      selectedColor: "#EEEEEE",
    },
  },
  components: {
    BackstagePage: {
      styleOverrides: {
        root: {
          overflow: "hidden",
          height: "calc(100hv-64px)",
        },
      },
    },
    BackstageHeader: {
      styleOverrides: {
        header: () => ({
          position: "relative",
        }),
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        tabRoot: {
          padding: "16px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#000000",
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none", // Subtle shadow
          borderRadius: "12px", // More rounded corners
          padding: "8px", // A little more padding for better spacing
          border: "1px solid rgba(0, 0, 0, 0.1)", // Light border for better contrast
          transition: "box-shadow 0.3s ease", // Smooth transition for hover effect
          "&:hover": {
            boxShadow:
              "0 2px 5px rgba(0, 0, 0, 0.19), 0 2px 2px rgba(0, 0, 0, 0.23)", // Elevate shadow on hover
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": [
          vodafoneBold,
          vodafoneExtraBold,
          vodafoneLight,
          vodafoneRegular,
        ],
        // Hide Default Backstage Support Button
        // eslint-disable-next-line quotes
        'button[data-testid="support-button"]': {
          display: "none",
        },
      },
    },
  },
  typography: {
    ...defaultTypography,
    fontFamily: "Vodafone",
    htmlFontSize: 16, // Base font size reduced
    h1: {
      fontFamily: "Vodafone",
      fontSize: "2rem", // Typically large for h1
      marginBottom: 5,
      fontWeight: 700,
    },
    h2: {
      fontFamily: "Vodafone",
      fontSize: "1.75rem", // Slightly smaller than h1
      marginBottom: 5,
      fontWeight: 700,
    },
    h3: {
      fontFamily: "Vodafone",
      fontSize: "1.5rem", // h3 usually medium-sized
      marginBottom: 5,
      fontWeight: 700,
    },
    h4: {
      fontFamily: "Vodafone",
      fontSize: "1.25rem", // h4 is smaller
      marginBottom: 5,
      fontWeight: 700,
    },
    h5: {
      fontFamily: "Vodafone",
      fontSize: "1rem", // h5 as the smallest heading
      marginBottom: 5,
      fontWeight: 700,
    },
  },
  defaultPageTheme: "home",
});
