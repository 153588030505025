// StatusBadge.tsx

import clsx from "clsx";
import useStyles from "./StatusBadgeStyles";
import { TicketStatus } from "./types";

interface StatusBadgeProps {
  status: string;
  text?: string;
  size?: "small" | "medium" | "large";
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({
  status,
  text,
  size = "medium",
}) => {
  const classes = useStyles();

  const getStatusClass = () => {
    switch (status.toLowerCase()) {
      case TicketStatus.IN_PROGRESS:
        return classes.inProgress;
      case TicketStatus.BLOCKED:
        return classes.blocked;
      case TicketStatus.DRAFT:
        return classes.draft;
      default:
        return classes.blocked;
    }
  };

  return (
    <span className={clsx(classes.badge, getStatusClass(), classes[size])}>
      {text || status.toUpperCase()}
    </span>
  );
};
