import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
  },
  paginationItem: {
    borderColor:
      theme.palette.type === "dark"
        ? "rgba(255, 255, 255, 0.3)"
        : "rgba(0, 0, 0, 0.3)",
    color:
      theme.palette.type === "dark"
        ? "rgba(255, 255, 255, 0.7)"
        : "rgba(0, 0, 0, 0.7)",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-selected": {
      backgroundColor:
        theme.palette.type === "dark"
          ? "rgba(255, 255, 255, 0.1)"
          : "rgba(0, 0, 0, 0.1)",
      borderColor:
        theme.palette.type === "dark"
          ? "rgba(255, 255, 255, 0.5)"
          : "rgba(0, 0, 0, 0.5)",
      color: theme.palette.type === "dark" ? "white " : "black",
    },
  },
}));

export default useStyles;
