import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  infoCard: {
    marginBottom: theme.spacing(3),
    "& + .MuiAlert-root": {
      marginTop: theme.spacing(3),
    },
  },
  releaseTitle: {
    ...theme.typography.h6,
    margin: 0,
    marginRight: "0.5rem",
  },
  releaseTagIcon: {
    verticalAlign: "middle",
  },
  sortSelector: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export default useStyles;
