import { Grid } from "@material-ui/core";

import { SelfService } from "../../components/self-service/SelfService";

export const selfServiceContent = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item lg={12}>
      <SelfService />
    </Grid>
  </Grid>
);
