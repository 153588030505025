import { EmptyState } from "@backstage/core-components";
import {
  EntityGithubActionsContent,
  isGithubActionsAvailable,
} from "@backstage-community/plugin-github-actions";
import { EntitySwitch } from "../../components/catalog";
import {
  EntityGitlabPipelinesTable,
  isGitlabAvailable,
} from "../../components/gitlab";

export const cicdContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <EntityGithubActionsContent />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isGitlabAvailable}>
      <EntityGitlabPipelinesTable />
    </EntitySwitch.Case>
    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it."
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);
