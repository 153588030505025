import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { Box, CircularProgress } from "@material-ui/core";
import { CustomPagination } from "../CustomPagination";
import { Alert } from "@mui/material";
import useStyles from "./CustomDataGridStyles";

interface CustomDataGridProps {
  columns: GridColDef[];
  rows: GridRowsProp;
  paginationModel: { pageSize: number; page: number };
  onPaginationModelChange: (model: { pageSize: number; page: number }) => void;
  loading?: boolean;
  error?: string;
}

export const CustomDataGrid: React.FC<CustomDataGridProps> = ({
  rows,
  columns,
  paginationModel,
  onPaginationModelChange,
  loading,
  error,
}) => {
  const classes = useStyles();
  return (
    <Box>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{`Error: ${error}`}</Alert>
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          pageSizeOptions={[5]}
          className={classes.dataGridRoot}
          slots={{
            pagination: CustomPagination,
          }}
        />
      )}
    </Box>
  );
};
