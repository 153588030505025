import { PropsWithChildren } from "react";
import { Box } from "@material-ui/core";
import TopBar from "./TopBar";
import useStyles from "./RootStyles";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export const Root = ({ children }: PropsWithChildren<{}>) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.navbar}>
        <TopBar />
      </Box>
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};
