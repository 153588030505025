// Content from https://github.com/github/linguist/blob/master/lib/linguist/languages.yml
type Colors = {
  [key: string]: {
    color: string;
  };
};

export const colors: Colors = {
  "1C Enterprise": {
    color: "#814CCC",
  },
  "4D": {
    color: "inherit",
  },
  ABAP: {
    color: "#E8274B",
  },
  ActionScript: {
    color: "#882B0F",
  },
  Ada: {
    color: "#02f88c",
  },
  Agda: {
    color: "#315665",
  },
  "AGS Script": {
    color: "#B9D9FF",
  },
  AL: {
    color: "#3AA2B5",
  },
  Alloy: {
    color: "#64C800",
  },
  "Alpine Abuild": {
    color: "inherit",
  },
  AMPL: {
    color: "#E6EFBB",
  },
  AngelScript: {
    color: "#C7D7DC",
  },
  ANTLR: {
    color: "#9DC3FF",
  },
  Apex: {
    color: "#1797c0",
  },
  "API Blueprint": {
    color: "#2ACCA8",
  },
  APL: {
    color: "#5A8164",
  },
  "Apollo Guidance Computer": {
    color: "#0B3D91",
  },
  AppleScript: {
    color: "#101F1F",
  },
  Arc: {
    color: "#aa2afe",
  },
  ASL: {
    color: "inherit",
  },
  "ASP.NET": {
    color: "#9400ff",
  },
  AspectJ: {
    color: "#a957b0",
  },
  Assembly: {
    color: "#6E4C13",
  },
  Asymptote: {
    color: "#ff0000",
  },
  ATS: {
    color: "#1ac620",
  },
  Augeas: {
    color: "inherit",
  },
  AutoHotkey: {
    color: "#6594b9",
  },
  AutoIt: {
    color: "#1C3552",
  },
  Awk: {
    color: "inherit",
  },
  Ballerina: {
    color: "#FF5000",
  },
  Batchfile: {
    color: "#C1F12E",
  },
  Befunge: {
    color: "inherit",
  },
  Bison: {
    color: "#6A463F",
  },
  BitBake: {
    color: "inherit",
  },
  Blade: {
    color: "#f7523f",
  },
  BlitzBasic: {
    color: "inherit",
  },
  BlitzMax: {
    color: "#cd6400",
  },
  Bluespec: {
    color: "inherit",
  },
  Boo: {
    color: "#d4bec1",
  },
  Brainfuck: {
    color: "#2F2530",
  },
  Brightscript: {
    color: "inherit",
  },
  C: {
    color: "#555555",
  },
  "C#": {
    color: "#178600",
  },
  "C++": {
    color: "#f34b7d",
  },
  "C2hs Haskell": {
    color: "inherit",
  },
  "Cap'n Proto": {
    color: "inherit",
  },
  CartoCSS: {
    color: "inherit",
  },
  Ceylon: {
    color: "#dfa535",
  },
  Chapel: {
    color: "#8dc63f",
  },
  Charity: {
    color: "inherit",
  },
  ChucK: {
    color: "inherit",
  },
  Cirru: {
    color: "#ccccff",
  },
  Clarion: {
    color: "#db901e",
  },
  "Classic ASP": {
    color: "#6a40fd",
  },
  Clean: {
    color: "#3F85AF",
  },
  Click: {
    color: "#E4E6F3",
  },
  CLIPS: {
    color: "inherit",
  },
  Clojure: {
    color: "#db5855",
  },
  CMake: {
    color: "inherit",
  },
  COBOL: {
    color: "inherit",
  },
  CodeQL: {
    color: "inherit",
  },
  CoffeeScript: {
    color: "#244776",
  },
  ColdFusion: {
    color: "#ed2cd6",
  },
  "ColdFusion CFC": {
    color: "#ed2cd6",
  },
  "Common Lisp": {
    color: "#3fb68b",
  },
  "Common Workflow Language": {
    color: "#B5314C",
  },
  "Component Pascal": {
    color: "#B0CE4E",
  },
  Cool: {
    color: "inherit",
  },
  Coq: {
    color: "inherit",
  },
  Crystal: {
    color: "#000100",
  },
  CSON: {
    color: "#244776",
  },
  Csound: {
    color: "inherit",
  },
  "Csound Document": {
    color: "inherit",
  },
  "Csound Score": {
    color: "inherit",
  },
  CSS: {
    color: "#563d7c",
  },
  Cuda: {
    color: "#3A4E3A",
  },
  CWeb: {
    color: "inherit",
  },
  Cycript: {
    color: "inherit",
  },
  Cython: {
    color: "inherit",
  },
  D: {
    color: "#ba595e",
  },
  Dafny: {
    color: "#FFEC25",
  },
  Dart: {
    color: "#00B4AB",
  },
  Gnuplot: {
    color: "#f0a9f0",
  },
  DataWeave: {
    color: "#003a52",
  },
  Dhall: {
    color: "#dfafff",
  },
  "DIGITAL Command Language": {
    color: "inherit",
  },
  DM: {
    color: "#447265",
  },
  Dockerfile: {
    color: "#384d54",
  },
  Dogescript: {
    color: "#cca760",
  },
  DTrace: {
    color: "inherit",
  },
  Dylan: {
    color: "#6c616e",
  },
  E: {
    color: "#ccce35",
  },
  eC: {
    color: "#913960",
  },
  ECL: {
    color: "#8a1267",
  },
  ECLiPSe: {
    color: "inherit",
  },
  Eiffel: {
    color: "#4d6977",
  },
  EJS: {
    color: "#a91e50",
  },
  Elixir: {
    color: "#6e4a7e",
  },
  Elm: {
    color: "#60B5CC",
  },
  "Emacs Lisp": {
    color: "#c065db",
  },
  EmberScript: {
    color: "#FFF4F3",
  },
  EQ: {
    color: "#a78649",
  },
  Erlang: {
    color: "#B83998",
  },
  "F#": {
    color: "#b845fc",
  },
  "F*": {
    color: "#572e30",
  },
  Factor: {
    color: "#636746",
  },
  Fancy: {
    color: "#7b9db4",
  },
  Fantom: {
    color: "#14253c",
  },
  Faust: {
    color: "#c37240",
  },
  "Filebench WML": {
    color: "inherit",
  },
  Filterscript: {
    color: "inherit",
  },
  fish: {
    color: "inherit",
  },
  FLUX: {
    color: "#88ccff",
  },
  Forth: {
    color: "#341708",
  },
  Fortran: {
    color: "#4d41b1",
  },
  "Fortran Free Form": {
    color: "inherit",
  },
  FreeMarker: {
    color: "#0050b2",
  },
  Frege: {
    color: "#00cafe",
  },
  Futhark: {
    color: "#5f021f",
  },
  "G-code": {
    color: "#D08CF2",
  },
  "Game Maker Language": {
    color: "#71b417",
  },
  GAML: {
    color: "#FFC766",
  },
  GAMS: {
    color: "inherit",
  },
  GAP: {
    color: "inherit",
  },
  "GCC Machine Description": {
    color: "inherit",
  },
  GDB: {
    color: "inherit",
  },
  GDScript: {
    color: "#355570",
  },
  Genie: {
    color: "#fb855d",
  },
  Genshi: {
    color: "inherit",
  },
  "Gentoo Ebuild": {
    color: "inherit",
  },
  "Gentoo Eclass": {
    color: "inherit",
  },
  Gherkin: {
    color: "#5B2063",
  },
  GLSL: {
    color: "inherit",
  },
  Glyph: {
    color: "#c1ac7f",
  },
  Go: {
    color: "#00ADD8",
  },
  Golo: {
    color: "#88562A",
  },
  Gosu: {
    color: "#82937f",
  },
  Grace: {
    color: "inherit",
  },
  "Grammatical Framework": {
    color: "#ff0000",
  },
  GraphQL: {
    color: "#e10098",
  },
  Groovy: {
    color: "#e69f56",
  },
  "Groovy Server Pages": {
    color: "inherit",
  },
  Hack: {
    color: "#878787",
  },
  Haml: {
    color: "#ece2a9",
  },
  Handlebars: {
    color: "#f7931e",
  },
  Harbour: {
    color: "#0e60e3",
  },
  Haskell: {
    color: "#5e5086",
  },
  Haxe: {
    color: "#df7900",
  },
  HCL: {
    color: "#00dfd4",
  },
  HiveQL: {
    color: "#dce200",
  },
  HLSL: {
    color: "inherit",
  },
  HolyC: {
    color: "#ffefaf",
  },
  HTML: {
    color: "#e34c26",
  },
  Hy: {
    color: "#7790B2",
  },
  HyPhy: {
    color: "inherit",
  },
  IDL: {
    color: "#a3522f",
  },
  Idris: {
    color: "#b30000",
  },
  "IGOR Pro": {
    color: "#0000cc",
  },
  "Inform 7": {
    color: "inherit",
  },
  "Inno Setup": {
    color: "inherit",
  },
  Io: {
    color: "#a9188d",
  },
  Ioke: {
    color: "#078193",
  },
  Isabelle: {
    color: "#FEFE00",
  },
  "Isabelle ROOT": {
    color: "inherit",
  },
  J: {
    color: "#9EEDFF",
  },
  Jasmin: {
    color: "inherit",
  },
  Java: {
    color: "#b07219",
  },
  "Java Server Pages": {
    color: "inherit",
  },
  JavaScript: {
    color: "#f1e05a",
  },
  "JavaScript+ERB": {
    color: "inherit",
  },
  JFlex: {
    color: "#DBCA00",
  },
  Jison: {
    color: "inherit",
  },
  "Jison Lex": {
    color: "inherit",
  },
  Jolie: {
    color: "#843179",
  },
  JSONiq: {
    color: "#40d47e",
  },
  Jsonnet: {
    color: "#0064bd",
  },
  JSX: {
    color: "inherit",
  },
  Julia: {
    color: "#a270ba",
  },
  "Jupyter Notebook": {
    color: "#DA5B0B",
  },
  "Kaitai Struct": {
    color: "#773b37",
  },
  Kotlin: {
    color: "#F18E33",
  },
  KRL: {
    color: "#28430A",
  },
  LabVIEW: {
    color: "inherit",
  },
  Lasso: {
    color: "#999999",
  },
  Latte: {
    color: "#f2a542",
  },
  Lean: {
    color: "inherit",
  },
  Less: {
    color: "#1d365d",
  },
  Lex: {
    color: "#DBCA00",
  },
  LFE: {
    color: "#4C3023",
  },
  LilyPond: {
    color: "inherit",
  },
  Limbo: {
    color: "inherit",
  },
  "Literate Agda": {
    color: "inherit",
  },
  "Literate CoffeeScript": {
    color: "inherit",
  },
  "Literate Haskell": {
    color: "inherit",
  },
  LiveScript: {
    color: "#499886",
  },
  LLVM: {
    color: "#185619",
  },
  Logos: {
    color: "inherit",
  },
  Logtalk: {
    color: "inherit",
  },
  LOLCODE: {
    color: "#cc9900",
  },
  LookML: {
    color: "#652B81",
  },
  LoomScript: {
    color: "inherit",
  },
  LSL: {
    color: "#3d9970",
  },
  Lua: {
    color: "#000080",
  },
  M: {
    color: "inherit",
  },
  M4: {
    color: "inherit",
  },
  M4Sugar: {
    color: "inherit",
  },
  Macaulay2: {
    color: "#d8ffff",
  },
  Makefile: {
    color: "#427819",
  },
  Mako: {
    color: "inherit",
  },
  Markdown: {
    color: "#083fa1",
  },
  Marko: {
    color: "#42bff2",
  },
  Mask: {
    color: "#f97732",
  },
  Mathematica: {
    color: "inherit",
  },
  MATLAB: {
    color: "#e16737",
  },
  Max: {
    color: "#c4a79c",
  },
  MAXScript: {
    color: "#00a6a6",
  },
  mcfunction: {
    color: "#E22837",
  },
  Mercury: {
    color: "#ff2b2b",
  },
  Meson: {
    color: "#007800",
  },
  Metal: {
    color: "#8f14e9",
  },
  MiniD: {
    color: "inherit",
  },
  Mirah: {
    color: "#c7a938",
  },
  "mIRC Script": {
    color: "#3d57c3",
  },
  MLIR: {
    color: "#5EC8DB",
  },
  Modelica: {
    color: "inherit",
  },
  "Modula-2": {
    color: "inherit",
  },
  "Modula-3": {
    color: "#223388",
  },
  "Module Management System": {
    color: "inherit",
  },
  Monkey: {
    color: "inherit",
  },
  Moocode: {
    color: "inherit",
  },
  MoonScript: {
    color: "inherit",
  },
  "Motorola 68K Assembly": {
    color: "inherit",
  },
  MQL4: {
    color: "#62A8D6",
  },
  MQL5: {
    color: "#4A76B8",
  },
  MTML: {
    color: "#b7e1f4",
  },
  MUF: {
    color: "inherit",
  },
  mupad: {
    color: "inherit",
  },
  Myghty: {
    color: "inherit",
  },
  NASL: {
    color: "inherit",
  },
  NCL: {
    color: "#28431f",
  },
  Nearley: {
    color: "#990000",
  },
  Nemerle: {
    color: "#3d3c6e",
  },
  nesC: {
    color: "#94B0C7",
  },
  NetLinx: {
    color: "#0aa0ff",
  },
  "NetLinx+ERB": {
    color: "#747faa",
  },
  NetLogo: {
    color: "#ff6375",
  },
  NewLisp: {
    color: "#87AED7",
  },
  Nextflow: {
    color: "#3ac486",
  },
  Nim: {
    color: "#ffc200",
  },
  Nit: {
    color: "#009917",
  },
  Nix: {
    color: "#7e7eff",
  },
  NSIS: {
    color: "inherit",
  },
  Nu: {
    color: "#c9df40",
  },
  NumPy: {
    color: "#9C8AF9",
  },
  "Objective-C": {
    color: "#438eff",
  },
  "Objective-C++": {
    color: "#6866fb",
  },
  "Objective-J": {
    color: "#ff0c5a",
  },
  ObjectScript: {
    color: "#424893",
  },
  OCaml: {
    color: "#3be133",
  },
  Odin: {
    color: "#60AFFE",
  },
  Omgrofl: {
    color: "#cabbff",
  },
  ooc: {
    color: "#b0b77e",
  },
  Opa: {
    color: "inherit",
  },
  Opal: {
    color: "#f7ede0",
  },
  "Open Policy Agent": {
    color: "inherit",
  },
  OpenCL: {
    color: "inherit",
  },
  "OpenEdge ABL": {
    color: "inherit",
  },
  OpenQASM: {
    color: "#AA70FF",
  },
  "OpenRC runscript": {
    color: "inherit",
  },
  OpenSCAD: {
    color: "inherit",
  },
  Ox: {
    color: "inherit",
  },
  Oxygene: {
    color: "#cdd0e3",
  },
  Oz: {
    color: "#fab738",
  },
  P4: {
    color: "#7055b5",
  },
  Pan: {
    color: "#cc0000",
  },
  Papyrus: {
    color: "#6600cc",
  },
  Parrot: {
    color: "#f3ca0a",
  },
  "Parrot Assembly": {
    color: "inherit",
  },
  "Parrot Internal Representation": {
    color: "inherit",
  },
  Pascal: {
    color: "#E3F171",
  },
  Pawn: {
    color: "#dbb284",
  },
  Pep8: {
    color: "#C76F5B",
  },
  Perl: {
    color: "#0298c3",
  },
  PHP: {
    color: "#4F5D95",
  },
  PicoLisp: {
    color: "inherit",
  },
  PigLatin: {
    color: "#fcd7de",
  },
  Pike: {
    color: "#005390",
  },
  PLpgSQL: {
    color: "inherit",
  },
  PLSQL: {
    color: "#dad8d8",
  },
  PogoScript: {
    color: "#d80074",
  },
  Pony: {
    color: "inherit",
  },
  PostScript: {
    color: "#da291c",
  },
  "POV-Ray SDL": {
    color: "inherit",
  },
  PowerBuilder: {
    color: "#8f0f8d",
  },
  PowerShell: {
    color: "#012456",
  },
  Prisma: {
    color: "#0c344b",
  },
  Processing: {
    color: "#0096D8",
  },
  Prolog: {
    color: "#74283c",
  },
  "Propeller Spin": {
    color: "#7fa2a7",
  },
  Pug: {
    color: "#a86454",
  },
  Puppet: {
    color: "#302B6D",
  },
  PureBasic: {
    color: "#5a6986",
  },
  PureScript: {
    color: "#1D222D",
  },
  Python: {
    color: "#3572A5",
  },
  "Python console": {
    color: "inherit",
  },
  q: {
    color: "#0040cd",
  },
  "Q#": {
    color: "#fed659",
  },
  QMake: {
    color: "inherit",
  },
  QML: {
    color: "#44a51c",
  },
  "Qt Script": {
    color: "#00b841",
  },
  Quake: {
    color: "#882233",
  },
  R: {
    color: "#198CE7",
  },
  Racket: {
    color: "#3c5caa",
  },
  Ragel: {
    color: "#9d5200",
  },
  Raku: {
    color: "#0000fb",
  },
  RAML: {
    color: "#77d9fb",
  },
  Rascal: {
    color: "#fffaa0",
  },
  REALbasic: {
    color: "inherit",
  },
  Reason: {
    color: "#ff5847",
  },
  Rebol: {
    color: "#358a5b",
  },
  Red: {
    color: "#f50000",
  },
  Redcode: {
    color: "inherit",
  },
  "Ren'Py": {
    color: "#ff7f7f",
  },
  RenderScript: {
    color: "inherit",
  },
  REXX: {
    color: "inherit",
  },
  Ring: {
    color: "#2D54CB",
  },
  Riot: {
    color: "#A71E49",
  },
  RobotFramework: {
    color: "inherit",
  },
  Roff: {
    color: "#ecdebe",
  },
  Rouge: {
    color: "#cc0088",
  },
  RPC: {
    color: "inherit",
  },
  Ruby: {
    color: "#701516",
  },
  RUNOFF: {
    color: "#665a4e",
  },
  Rust: {
    color: "#dea584",
  },
  Sage: {
    color: "inherit",
  },
  SaltStack: {
    color: "#646464",
  },
  SAS: {
    color: "#B34936",
  },
  Sass: {
    color: "#a53b70",
  },
  Scala: {
    color: "#c22d40",
  },
  Scheme: {
    color: "#1e4aec",
  },
  Scilab: {
    color: "inherit",
  },
  SCSS: {
    color: "#c6538c",
  },
  sed: {
    color: "#64b970",
  },
  Self: {
    color: "#0579aa",
  },
  ShaderLab: {
    color: "inherit",
  },
  Shell: {
    color: "#89e051",
  },
  ShellSession: {
    color: "inherit",
  },
  Shen: {
    color: "#120F14",
  },
  Sieve: {
    color: "inherit",
  },
  Slash: {
    color: "#007eff",
  },
  Slice: {
    color: "#003fa2",
  },
  Slim: {
    color: "#2b2b2b",
  },
  Smali: {
    color: "inherit",
  },
  Smalltalk: {
    color: "#596706",
  },
  Smarty: {
    color: "#bedf00",
  },
  SmPL: {
    color: "#c94949",
  },
  SMT: {
    color: "inherit",
  },
  Solidity: {
    color: "#AA6746",
  },
  SourcePawn: {
    color: "#f69e1d",
  },
  SQF: {
    color: "#3F3F3F",
  },
  SQLPL: {
    color: "inherit",
  },
  Squirrel: {
    color: "#800000",
  },
  "SRecode Template": {
    color: "#348a34",
  },
  Stan: {
    color: "#b2011d",
  },
  "Standard ML": {
    color: "#dc566d",
  },
  Starlark: {
    color: "#76d275",
  },
  Stata: {
    color: "inherit",
  },
  Stylus: {
    color: "#ff6347",
  },
  SuperCollider: {
    color: "#46390b",
  },
  Svelte: {
    color: "#ff3e00",
  },
  SVG: {
    color: "#ff9900",
  },
  Swift: {
    color: "#ffac45",
  },
  SWIG: {
    color: "inherit",
  },
  SystemVerilog: {
    color: "#DAE1C2",
  },
  Tcl: {
    color: "#e4cc98",
  },
  Tcsh: {
    color: "inherit",
  },
  Terra: {
    color: "#00004c",
  },
  TeX: {
    color: "#3D6117",
  },
  Thrift: {
    color: "inherit",
  },
  "TI Program": {
    color: "#A0AA87",
  },
  TLA: {
    color: "inherit",
  },
  TSQL: {
    color: "inherit",
  },
  TSX: {
    color: "inherit",
  },
  Turing: {
    color: "#cf142b",
  },
  Twig: {
    color: "#c1d026",
  },
  TXL: {
    color: "inherit",
  },
  TypeScript: {
    color: "#2b7489",
  },
  "Unified Parallel C": {
    color: "#4e3617",
  },
  "Unix Assembly": {
    color: "inherit",
  },
  Uno: {
    color: "#9933cc",
  },
  UnrealScript: {
    color: "#a54c4d",
  },
  UrWeb: {
    color: "inherit",
  },
  V: {
    color: "#4f87c4",
  },
  Vala: {
    color: "#fbe5cd",
  },
  VBA: {
    color: "#867db1",
  },
  VBScript: {
    color: "#15dcdc",
  },
  VCL: {
    color: "#148AA8",
  },
  Verilog: {
    color: "#b2b7f8",
  },
  VHDL: {
    color: "#adb2cb",
  },
  "Vim script": {
    color: "#199f4b",
  },
  "Visual Basic .NET": {
    color: "#945db7",
  },
  Volt: {
    color: "#1F1F1F",
  },
  Vue: {
    color: "#2c3e50",
  },
  wdl: {
    color: "#42f1f4",
  },
  WebAssembly: {
    color: "#04133b",
  },
  WebIDL: {
    color: "inherit",
  },
  wisp: {
    color: "#7582D1",
  },
  Wollok: {
    color: "#a23738",
  },
  X10: {
    color: "#4B6BEF",
  },
  xBase: {
    color: "#403a40",
  },
  XC: {
    color: "#99DA07",
  },
  Xojo: {
    color: "inherit",
  },
  XProc: {
    color: "inherit",
  },
  XQuery: {
    color: "#5232e7",
  },
  XS: {
    color: "inherit",
  },
  XSLT: {
    color: "#EB8CEB",
  },
  Xtend: {
    color: "inherit",
  },
  Yacc: {
    color: "#4B6C4B",
  },
  YAML: {
    color: "#cb171e",
  },
  YARA: {
    color: "#220000",
  },
  YASnippet: {
    color: "#32AB90",
  },
  ZAP: {
    color: "#0d665e",
  },
  Zeek: {
    color: "inherit",
  },
  ZenScript: {
    color: "#00BCD1",
  },
  Zephir: {
    color: "#118f9e",
  },
  Zig: {
    color: "#ec915c",
  },
  ZIL: {
    color: "#dc75e5",
  },
  Zimpl: {
    color: "inherit",
  },
};
