import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  badge: {
    padding: "2px 8px",
    borderRadius: "8px",
    color: "#fff",
    fontWeight: "bold",
    display: "inline-block",
    textTransform: "uppercase",
    marginRight: "0.5rem",
  },
  open: {
    backgroundColor: "#22863a",
  },
  closed: {
    backgroundColor: "#cb2431",
  },
  merged: {
    backgroundColor: "#6f42c1",
  },
  draft: {
    backgroundColor: "#6a737d",
  },
}));

export default useStyles;
