import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 150,
    },
  }),
);

export default useStyles;
